import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { CompressorService } from 'src/app/services/compressor.service';
import { FaqService } from 'src/app/services/faq.service';
import { MiscService } from 'src/app/services/misc.service';
import { StateService } from 'src/app/services/state.service';
import { SupportTicketService } from 'src/app/services/support.ticket.service';

@Component({
  selector: 'app-create-support-ticket-dialog',
  templateUrl: './createSupportTicketDialog.component.html',
  styleUrls: [
    './createSupportTicketDialog.component.scss',
    '../../dialogs.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class CreateSupportTicketDialogComponent implements OnInit {
  @ViewChild('imagesPicker', { static: true }) imagesPicker: ElementRef;
  @ViewChild('docsPicker', { static: true }) docsPicker: ElementRef;

  user: User;

  mainCats: any[] = [];
  requestCategories: any[] = [];

  supportTicket = {
    topic: null,
    subTopic: null,
    mainConcern: '',
    detailConcern: '',
    cancelTaskId: null,
    taskId: null,
    userId: null,
    email: '',
    docs: [],
    images: [],
    stripeChargeId: '',
    stripePayoutId: '',
    assigneeId: null,
    faqTopic: null,
  };

  constructor(
    private supportTicketService: SupportTicketService,
    private toastr: ToastrService,
    private faqService: FaqService,
    private stateService: StateService,
    private compressor: CompressorService,
    private miscService: MiscService,
    public dialogRef: MatDialogRef<CreateSupportTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.supportTicket.userId = this.user.id;
    this.supportTicket.email = this.user.email;

    this.fetchLocalizedMainCats('dk');
    this.fetchSupportMainCats();
  }

  fetchSupportMainCats(countryCode: string = 'dk') {
    this.supportTicketService.getSupportMainCategories().subscribe((res) => {
      if (res) {
        if (countryCode === 'dk') {
          this.requestCategories = res[0].categories.dk;
        } else {
          this.requestCategories = res[0].categories.eng;
        }
      }
    });
  }

  fetchLocalizedMainCats(countryCode: string) {
    this.faqService.getMainFaqs().subscribe((res) => {
      if (res) {
        if (countryCode === 'dk') {
          this.mainCats = res.DK;
        } else {
          this.mainCats = res.ENG;
        }
      }
    });
  }

  onTopicChange(event) {
    this.supportTicket.topic = event.value.issue;
    this.supportTicket.subTopic = event.value.cat;
    this.supportTicket.faqTopic = this.supportTicketService.mapSupportCategoryToFaqTopic(event.value.cat);
  }

  async onImagesPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.compressAndUploadImages(filesArray);
      this.supportTicket.images = [...(this.supportTicket.images || []), ...uploadedFiles.files];
      input.value = '';
    }
  }

  async onDocumentsPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.uploadDocuments(filesArray);
      this.supportTicket.docs = [...(this.supportTicket.docs || []), ...uploadedFiles.files];
      input.value = '';
    }
  }

  async compressAndUploadImages(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise(async (resolve, reject) => {
      if (files.length > 0) {
        const toCompressedImages = await Promise.all(
          files.map((file) => this.compressor.compress2(file)),
        );
        this.miscService
          .uploadMedia(
            { folderUploadPath: 'supportTicket/message' },
            toCompressedImages,
          )
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  async uploadDocuments(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        this.miscService
          .uploadMedia({ folderUploadPath: 'supportTicket/message' }, files)
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  removePreviewImage(image: File) {
    this.supportTicket.images = this.supportTicket.images.filter((img: File) => img !== image);
  }

  removePreviewDoc(doc: File) {
    this.supportTicket.docs = this.supportTicket.docs.filter((file: File) => file !== doc);
  }

  openPreviewImage(image) {
    const imageUrl = image?.location || image.url;
    window.open(imageUrl, '_blank');
  }

  createSupportTicket() {
    this.stateService.toggleWaitingPage(true);
    this.supportTicketService.createSupportTicketFromAdmin(this.supportTicket).subscribe({
      next: (supportTicket) => {
        this.toastr.success('Support ticket created successfully');
        this.dialogRef.close(supportTicket);
        this.stateService.toggleWaitingPage(false);
      },
      error: (error) => {
        console.error('Error creating support ticket:', error);
        this.toastr.error('Error creating support ticket');
        this.stateService.toggleWaitingPage(false);
      },
    })
  }
}
