<h2 mat-dialog-title>New support ticket</h2>
<mat-dialog-content>
  <div style="display: flex; flex-direction: column; gap: 0.5rem">
    <mat-form-field>
      <mat-label>Select topic</mat-label>
      <mat-select
        required
        (selectionChange)="onTopicChange($event)"
      >
        @for (cat of requestCategories; track $index) {
          <mat-option [value]="cat">{{ cat.issue }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [(ngModel)]="supportTicket.mainConcern"
        placeholder="Ticket title"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        [(ngModel)]="supportTicket.detailConcern"
        placeholder="Ticket message"
        required
      ></textarea>
    </mat-form-field>

    <!-- File Upload Section -->
    @if (supportTicket.images?.length > 0) {
      <div class="attached-files">
        @for (image of supportTicket.images; track $index) {
          <div class="image">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              (click)="openPreviewImage(image)"
            >
              <mat-icon>image</mat-icon>
              <p>{{ image.originalname }}</p>
            </div>
            <div
              style="display: flex; cursor: pointer"
              (click)="removePreviewImage(image)"
            >
              <mat-icon>close</mat-icon>
            </div>
          </div>
        }
      </div>
    }
    @if (supportTicket.docs?.length > 0) {
      <div class="attached-files">
        @for (doc of supportTicket.docs; track $index) {
          <div class="image">
            <div style="display: flex; align-items: center; cursor: pointer">
              <mat-icon>file_present</mat-icon>
              <p>{{ doc.originalname }}</p>
            </div>
            <div
              style="display: flex; cursor: pointer"
              (click)="removePreviewDoc(doc)"
            >
              <mat-icon>close</mat-icon>
            </div>
          </div>
        }
      </div>
    }

    <!-- Upload Buttons -->
    <div class="file-buttons">
      <button
        mat-stroked-button
        (click)="imagesPicker.click()"
        type="button"
      >
        <mat-icon>image</mat-icon>
        Add Images
      </button>
      <button
        mat-stroked-button
        (click)="docsPicker.click()"
        type="button"
      >
        <mat-icon>file_present</mat-icon>
        Add Documents
      </button>
    </div>

    <input
      accept="image/*"
      multiple
      style="display: none"
      type="file"
      #imagesPicker
      (change)="onImagesPicked($event)"
    />
    <input
      accept=".doc,.docx,.pdf"
      multiple
      style="display: none"
      type="file"
      #docsPicker
      (change)="onDocumentsPicked($event)"
    />

    @if (user?.tasks && user.tasks.length > 0) {
      <mat-form-field>
        <mat-label>Associate task</mat-label>
        <mat-select
          placeholder="None chosen"
          [(ngModel)]="supportTicket.taskId"
        >
          <mat-option [value]="null">None</mat-option>
          @for (task of user.tasks; track $index) {
            <mat-option [value]="task.id">
              ID: {{ task.id }} | {{ task.title }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    color="warn"
    mat-raised-button
  >
    Cancel
  </button>
  <button
    [disabled]="!supportTicket.mainConcern || !supportTicket.detailConcern"
    color="primary"
    mat-raised-button
    (click)="createSupportTicket()"
  >
    Create
  </button>
</mat-dialog-actions>
