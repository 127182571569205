<h2 mat-dialog-title>User's support tickets</h2>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Open Tickets">
      <div class="tickets-container">
        @for (ticket of openSupportTickets; track $index) {
          <div
            class="ticket-card"
            [matTooltip]="ticket.status"
            (click)="openTicket($event, ticket.uid)"
          >
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ ticket.mainConcern }}</mat-card-title>
                <mat-card-subtitle>{{
                  ticket.createdAt | date
                }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p>{{ ticket.detailConcern }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        }
      </div>
    </mat-tab>
    <mat-tab label="Closed Tickets">
      <div class="tickets-container">
        @for (ticket of closedSupportTickets; track $index) {
          <div
            class="ticket-card"
            [matTooltip]="ticket.status"
            (click)="openTicket($event, ticket.uid)"
          >
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ ticket.mainConcern }}</mat-card-title>
                <mat-card-subtitle>{{
                  ticket.createdAt | date
                }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p>{{ ticket.detailConcern }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
