import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { StateService } from './state.service';
import { BrowserPushNotificationService } from './browserPushNotification.service';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import {
  SupportTicket,
  SupportTicketMessage,
  SupportTicketStatuses,
} from '../models/supportTicket.model';

type UserEvents = 'viewing_entity' | 'stop_viewing_entity';

type ServerEvents =
  | 'report'
  | 'report_removed'
  | 'report_re_activated'
  | 'support_ticket_created'
  | 'support_ticket_closed'
  | 'support_ticket_re_opened'
  | 'admin_action_request'
  | 'admin_action_request_handled'
  | 'users_online';

type EntityTypes = 'support_ticket' | 'report';

type ViewingEntityEventData = {
  entityId: string;
  entityType: EntityTypes;
};

type ViewingEntityClosedEventData = {
  entityId: string;
  entityType: EntityTypes;
};

type EventDataMap = {
  viewing_entity: ViewingEntityEventData;
  stop_viewing_entity: ViewingEntityClosedEventData;
};

type SocketEvent<T extends UserEvents> = {
  eventName: T;
  data: EventDataMap[T];
};

export type CurrentlyViewedAdminEntities = {
  [key in EntityTypes]: {
    [key: string]: User[];
  };
};

@Injectable()
export class SocketService {
  baseUrl = environment.socketUrl;
  private socket: Socket;

  constructor(
    private router: Router,
    private pushNotifService: BrowserPushNotificationService,
    private storage: StorageService,
    private stateService: StateService,
  ) {}

  connectSocket() {
    if (!this.socket || (this.socket && !this.socket.connected)) {
      this.socket = io(this.baseUrl, {
        query: { token: this.storage.token, socketId: this.storage.socketId },
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        autoConnect: true,
      });

      this.listenToEvents();
    }
  }

  // Emit events
  emitEvent<T extends UserEvents>(event: SocketEvent<T>) {
    this.socket.emit(event.eventName, event.data);
  }

  listenToEvents() {
    // For reconnection when server restarts - to 'restore' state
    // TODO: consider using a more robust solution, maybe using local storage?
    this.socket.on('connect', () => {
      const url =
        this.router.lastSuccessfulNavigation.extractedUrl.root.children.primary
          .segments;
      if (url.length > 1) {
        if (url[0].path === 'support-ticket') {
          this.emitEvent({
            eventName: 'viewing_entity',
            data: {
              entityId: url[1].path,
              entityType: 'support_ticket',
            },
          });
        }
        if (url[0].path === 'report') {
          this.emitEvent({
            eventName: 'viewing_entity',
            data: {
              entityId: url[1].path,
              entityType: 'report',
            },
          });
        }
      }
    });
    this.socket.on('report', (data: any) => {
      this.pushNotifService.createNotification(
        'Report',
        'New report received',
        () => {
          this.router.navigate(['/reports']);
        },
      );
      this.stateService.totalActiveReportsComponentSource.next(data);
    });

    this.socket.on('report_removed', (data: any) => {
      if (data.ids) {
        this.stateService.readActiveReportsComponentSource.next(data.ids);
      } else if (data.id) {
        this.stateService.readActiveReportsComponentSource.next(data.id);
      }
    });

    this.socket.on('report_re_activated', (data: any) => {
      this.stateService.totalActiveReportsComponentSource.next(data);
    });

    this.socket.on('support_ticket_created', (data: SupportTicket) => {
      this.pushNotifService.createNotification(
        'Support Ticket',
        'New support ticket received',
        () => {
          this.router.navigate(['/support-tickets-v2'], {
            queryParams: { uid: data.uid },
          });
        },
      );
      this.stateService.newSupportTicket(data);
      this.stateService.updateUnresolvedSupportTicketsCount(1);
    });

    this.socket.on('support_ticket_message_created', (data: SupportTicket) => {
      this.stateService.newSupportTicketMessage(data);

      if (data.status === 'resolved') {
        this.stateService.updateUnresolvedSupportTicketsCount(-1);
      } else {
        this.stateService.updateUnresolvedSupportTicketsCount(1);
      }
    });

    this.socket.on(
      'support_ticket_closed',
      (data: {
        supportTicket: SupportTicket;
        oldStatus: SupportTicketStatuses;
      }) => {
        this.stateService.supportTicketClosed(data);

        if (data.oldStatus === 'unresolved') {
          this.stateService.updateUnresolvedSupportTicketsCount(-1);
        }
      },
    );

    this.socket.on('support_ticket_updated', (data: SupportTicket) => {
      this.stateService.supportTicketUpdated(data);
    });

    this.socket.on('support_ticket_reminder_created', (data: any) => {
      const { reminder, updatedSupportTicket } = data;
      this.stateService.supportTicketReminderCreated(
        reminder,
        updatedSupportTicket[1][0],
      );

      this.stateService.updateUnresolvedSupportTicketsCount(-1);
    });

    this.socket.on('support_ticket_reminder_completed', (data: any) => {});

    this.socket.on('admin_action_request', (data: any) => {
      this.pushNotifService.createNotification(
        'Admin Action Request',
        'New admin action request received',
        () => {
          this.router.navigate(['/requests']);
        },
      );
      this.stateService.totalActiveAdminActionRequestsComponentSource.next(
        data,
      );
    });
    this.socket.on('admin_action_request_handled', (data: any) => {
      this.stateService.readActiveAdminActionRequestsComponentSource.next(data);
    });

    this.socket.on('users_online', (data: any) => {
      this.stateService.updateOnlineAdmins(data);
    });

    this.socket.on('viewing_entity', (data: CurrentlyViewedAdminEntities) => {
      this.stateService.updateCurrentlyViewedSupportTickets(
        data.support_ticket,
      );
      this.stateService.updateCurrentlyViewedReports(data.report);
    });
  }

  // Disconnect
  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  getSocketId() {
    return this.socket.id;
  }
}
