import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { SupportTicket } from 'src/app/models/supportTicket.model';
import { SupportTicketService } from 'src/app/services/support.ticket.service';

export interface SmartResponsesDialogData {
  supportTicket: SupportTicket;
}

export type EmailTemplateItem = {
  countryCode: 'dk' | 'eng';
  emailTemplate: string;
  question: string;
  response: string;
  _id: string;
}

@Component({
  selector: 'app-smart-responses',
  templateUrl: './smart-responses.component.html',
  styleUrls: [
    '../../../components/dialogs/dialogs.scss',
    './smart-responses.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatTreeModule,
    MatExpansionModule,
  ],
})
export class SmartResponsesDialogComponent implements OnInit {
  language: 'danish' | 'english' = 'danish';
  allPrioritizedEmailTemplates: any[] = [];
  engPrioritizedEmailTemplates: any[] = [];
  dkPrioritizedEmailTemplates: any[] = [];

  emailTemplates: any[] = [];
  filteredEmailTemplates: any = {
    dk: [],
    eng: [],
  };

  emailTemplateCategories: any = [
    { name: 'account', enName: 'Account', dkName: 'Konto' },
    { name: 'payment', enName: 'Payment', dkName: 'Betaling' },
    { name: 'cancellation', enName: 'Cancellation', dkName: 'Annullering' },
    { name: 'question', enName: 'Question', dkName: 'Spørgsmål' },
    { name: 'task', enName: 'Task', dkName: 'Opgave' },
    { name: 'conflict', enName: 'Conflict', dkName: 'Konflikt' },
    { name: 'guidelines', enName: 'Guidelines', dkName: 'Retningslinjer' },
    { name: 'document', enName: 'Document', dkName: 'Dokument' },
    { name: 'payout', enName: 'Payout', dkName: 'Udbetaling' },
    { name: 'tech', enName: 'Tech', dkName: 'Tech' },
  ];

  emailTemplateSelected = output<EmailTemplateItem>();

  constructor(
    public dialogRef: MatDialogRef<SmartResponsesDialogComponent>,
    private supportTicketService: SupportTicketService,
    @Inject(MAT_DIALOG_DATA) public data: SmartResponsesDialogData,
  ) {}

  ngOnInit(): void {
    this.loadPrioritizedEmailTemplates();
    this.fetchEmailTemplates();
  }

  selectEmailTempCategory(emailTempCat: any) {
    if (this.language === 'danish') {
      this.filteredEmailTemplates.dk = this.emailTemplates.filter(
        (template) =>
          template.cat === emailTempCat.name &&
          template.countryCode.toLowerCase() === 'dk',
      );
    } else {
      this.filteredEmailTemplates.eng = this.emailTemplates.filter(
        (template) =>
          template.cat === emailTempCat.name &&
          template.countryCode.toLowerCase() === 'eng',
      );
    }
  }

  fetchEmailTemplates() {
    this.supportTicketService.getSupportEmailTemplates().subscribe((res) => {
      if (res) {
        this.emailTemplates = res;
      }
    });
  }

  selectAndInsertEmailTemp(emailTemplate) {
    this.emailTemplateSelected.emit(emailTemplate);
    this.close();
  }

  loadPrioritizedEmailTemplates() {
    const data = {
      cat: this.data.supportTicket.subTopic,
      subCat: this.data.supportTicket.subTopic,
    };
    this.supportTicketService
    .getSupportTicketTemplateByCatOrSubCat(data)
    .subscribe((res) => {
      if (res && res.length > 0) {
        this.allPrioritizedEmailTemplates = res;
        this.dkPrioritizedEmailTemplates =
          this.allPrioritizedEmailTemplates.filter(
            (x) => x.countryCode.toLowerCase() === 'dk',
          );
        this.engPrioritizedEmailTemplates =
          this.allPrioritizedEmailTemplates.filter(
            (x) => x.countryCode.toLowerCase() === 'eng',
          );
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
