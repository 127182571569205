import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'user-notifications-list-dialog',
  templateUrl: './userNotificationsListDialog.component.html',
  styleUrls: ['../dialogs.scss', 'userNotificationsListDialog.component.scss'],
})
export class UserNotificationsListDialogComponent {
  notifications: any[] = [];

  displayNotifications: any[] = [];
  filterKeys: string[] = ['id', 'message', 'title', 'createdAt'];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserNotificationsListDialogComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {}

  async fetchNotifications() {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      const pageToRequest = this.lastPage + 1;
      await this.notificationService
        .getNotificationForUser(this.data.userId, pageToRequest, 20)
        .subscribe(
          (res) => {
            this.notifications = this.notifications.concat(res.notifications);
            this.lastPage = pageToRequest;
            this.hasMore = res.hasMore;
          },
          (error) => {
            console.error(error);
          },
          () => (this.isBusy = false),
        );
    }
  }

  updateDisplayNotifications(notifications: any[]): void {
    this.displayNotifications = notifications;
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
