<h2 mat-dialog-title>Support ticket reminder</h2>
<mat-dialog-content>
  <div class="reminder_info_container">
    <div class="reminder_info">
      <div>
        <h4>Created by</h4>
        <p>{{ reminder.user.firstName }}</p>
      </div>
      <div>
        <h4>Created</h4>
        <p>{{ reminder.createdAt | date: 'dd/MM/YY' }}</p>
      </div>
      <div>
        <h4>Expires</h4>
        <p>{{ reminder.expiresAt | date: 'dd/MM/YY' }}</p>
      </div>
    </div>
    <div>
      <h4>Reminder note</h4>
      <p>{{ reminder.note }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-button
    (click)="close()"
  >
    Close
  </button>
</mat-dialog-actions>
