import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { routing } from './app.routing';
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { TextFieldModule } from '@angular/cdk/text-field';
import { QuillModule } from 'ngx-quill';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OnlineUsersComponent } from './components/online-users/online-users.component';
import { UsersCurrentlyViewingComponent } from './components/users-currently-viewing/users-currently-viewing.component';
import { UserNoteComponent } from './components/cards/user-card/user-note-card/user-note-card.component';
import { RequestConflictCasePendingComponent } from './pages/requestHandlingPage/requestConflictCasePending/requestConflictCasePending.component';
import { RequestPayoutFailedComponent } from './pages/requestHandlingPage/requestPayoutFailed/requestPayoutFailed.component';
import { RequestTaskCancellationDeniedComponent } from './pages/requestHandlingPage/requestTaskCancellationDenied/requestTaskCancellationDenied.component';
import { RequestMultipleChargesComponent } from './pages/requestHandlingPage/requestMultipleCharges/requestMultipleCharges.component';
import { RequestPaymentFailedComponent } from './pages/requestHandlingPage/requestPaymentFailed/requestPaymentFailed.component';
import { RequestNewGiftCardComponent } from './pages/requestHandlingPage/requestNewGiftCard/requestNewGiftCard.component';

export const imports = [
  BrowserModule,
  DragDropModule,
  HttpClientModule,
  FormsModule,
  BrowserAnimationsModule,
  NgxWebstorageModule.forRoot(),
  routing,
  MaterialModule,
  ToastrModule.forRoot({ preventDuplicates: true }),
  TextFieldModule,
  QuillModule.forRoot(),
  OnlineUsersComponent,
  UsersCurrentlyViewingComponent,
  UserNoteComponent,
  RequestConflictCasePendingComponent,
  RequestPayoutFailedComponent,
  RequestTaskCancellationDeniedComponent,
  RequestMultipleChargesComponent,
  RequestPaymentFailedComponent,
  RequestNewGiftCardComponent,
];
