import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  SupportTicket,
  SupportTicketMessage,
  SupportTicketReminder,
  PaginatedSupportTickets,
} from '../models/supportTicket.model';

import { environment } from '../../environments/environment';
import { SocketService } from './socket.service';
import { logClass } from './logger.decorator';
import { User } from '../models/user.model';

export type FindSupportTicketsPaginationParams = {
  page: number;
  pageSize: number;
  status: 'open' | 'closed';
  hasMore?: boolean;
};

export type FindSupportTicketMessagesPaginationParams = {
  page: number;
  pageSize: number;
  hasMore?: boolean;
};

@logClass()
@Injectable()
export class SupportTicketService {
  baseHrf = `${environment.apiUrl}/api/support-ticket`;
  dashboardHref = `${environment.dashboardAPIUrl}/api/support/`;

  defaultSupportTicketsPaginationParams: FindSupportTicketsPaginationParams = {
    page: 1,
    pageSize: 10,
    status: 'open',
  };

  defaultSupportTicketMessagesPaginationParams: FindSupportTicketMessagesPaginationParams =
    {
      page: 1,
      pageSize: 10,
    };

  constructor(
    private http: HttpClient,
    private socketService: SocketService,
  ) {}

  supportCategoryMapping: Record<string, string> = {
    account: 'om-handyhand',
    authentication: 'om-handyhand',
    settings: 'om-handyhand',
    verification: 'om-handyhand',
    reviews: 'om-handyhand',
    'completion rate': 'om-handyhand',
    restriction: 'om-handyhand',
    deactivation: 'om-handyhand',
    cancellation: 'annullering-og-refundering',
    conflict: 'konflikter',
    payment: 'betaling',
    guidelines: 'hvordan-handyhand-virker',
    question: 'andre-spoergsmaal',
    task: 'generelt',
    document: 'generelt',
    payout: 'generelt',
  };

  mapSupportCategoryToFaqTopic(category: string): string {
    return this.supportCategoryMapping[category] || 'generelt';
  }

  markSupportTicketAsResolved(
    supportTicketId: number,
  ): Observable<SupportTicket> {
    const url = `${this.baseHrf}/${supportTicketId}/resolve`;
    return this.http.put<SupportTicket>(url, {});
  }

  getUnresolvedSupportTicketsCount(): Observable<number> {
    const url = `${this.baseHrf}/unresolved-count`;
    return this.http.get<number>(url);
  }

  getSupportTicketByUid(uid: string): Observable<SupportTicket> {
    const url = `${this.baseHrf}/detail/uid/${uid}`;
    return this.http.get<SupportTicket>(url);
  }

  getSupportTicketsPaginated(
    config: FindSupportTicketsPaginationParams = this
      .defaultSupportTicketsPaginationParams,
  ): Observable<PaginatedSupportTickets> {
    const url = `${this.baseHrf}/all`;
    return this.http.get<PaginatedSupportTickets>(url, {
      params: config,
    });
  }

  getUnhandledSupportTicketsCount(): Observable<number> {
    const url = `${this.baseHrf}/unhandled-count`;
    return this.http.get<number>(url);
  }

  getAllSupportTicketsFromUserId(userId: number): Observable<SupportTicket[]> {
    const url = `${this.baseHrf}/user/${userId}`;
    return this.http.get<SupportTicket[]>(url);
  }

  getSupportEmailTemplates(): Observable<any> {
    const url = `${this.dashboardHref}templates`;
    return this.http.get<any>(url);
  }

  getSupportTicketTemplateByCatOrSubCat(catData): Observable<any> {
    if (catData) {
      const url = `${this.dashboardHref}by-cat-or-subcat`;
      return this.http.post<any>(url, catData);
    }
  }

  getSupportMainCategories(): Observable<any> {
    const url = `${this.dashboardHref}`;
    return this.http.get<any>(url);
  }

  createSupportTicketFromAdmin(data): Observable<SupportTicket> {
    const url = `${this.baseHrf}/create/admin`;
    return this.http.post<SupportTicket>(url, data);
  }

  createSupportTicketMessage(
    supportTicketId: number,
    data: any,
  ): Observable<SupportTicketMessage> {
    const url = `${this.baseHrf}/${supportTicketId}/message/admin`;
    return this.http.post<SupportTicketMessage>(url, {
      ...data,
      supportTicketId,
    });
  }

  closeSupportTicket(supportTicketId: number): Observable<boolean> {
    const url = `${this.baseHrf}/${supportTicketId}/close`;
    return this.http.get<boolean>(url);
  }

  getAssignees(): Observable<User[]> {
    const url = `${this.baseHrf}/assignees`;
    return this.http.get<User[]>(url);
  }

  updateSupportTicketAssignee(
    supportTicketId: number,
    assigneeId: number | null,
  ): Observable<boolean> {
    const url = `${this.baseHrf}/${supportTicketId}/assignees`;
    return this.http.put<boolean>(url, { assigneeId });
  }

  createSupportTicketReminder(
    supportTicketId: number,
    reminderData: any,
  ): Observable<SupportTicketReminder> {
    const url = `${this.baseHrf}/${supportTicketId}/reminder`;
    return this.http.post<SupportTicketReminder>(url, {
      ...reminderData,
      supportTicketId,
    });
  }

  completeSupportTicketReminder(
    supportTicketId: number,
    supportTicketReminderId: number,
    completedAt: Date | null,
  ): Observable<SupportTicketReminder> {
    const url = `${this.baseHrf}/${supportTicketId}/reminder/${supportTicketReminderId}/complete`;
    return this.http.put<SupportTicketReminder>(url, { completedAt });
  }

  connectSupportTicketToUser(
    userId: number,
    supportTicketId: number,
  ): Observable<SupportTicket> {
    const url = `${this.baseHrf}/${supportTicketId}/connect-ticket-to-user`;
    return this.http.put<SupportTicket>(url, {
      userId,
    });
  }
}
