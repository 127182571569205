<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>
            User {{ user.id }} got charged multiple times for the same
            {{ query.offerPriceIncreaseId ? 'opi' : 'offer' }}
          </h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="multiple-charges-info">
          <a href="stripe.com">Link</a>
          <div>
            <p>
              It seems that something went wrong when accepting
              @if (query.offerPriceIncreaseId) {
                offer price increase: {{ query.offerPriceIncreaseId }}
              } @else {
                offer: {{ query.offerId }}
              }
              on task {{ taskId }}.
            </p>
            <p>
              User {{ user.id }} managed to get successfully charged multiple
              times:
            </p>
            <ul>
              @for (link of links; track $index) {
                <li>
                  <p>{{ link.text }}</p>
                  <a href="{{ link.url }}">{{ link.url }}</a>
                </li>
              }
            </ul>
          </div>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
