import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationLog } from '../models/notificationLog.model';
import { NotificationScheduled } from '../models/notificationSchedule';
import { NotificationSegment } from '../models/notificationSegment';

type ListNotificationSegmentResponse = {
  notifications: NotificationSegment[];
  hasMore: boolean;
};

type ListNotificationLogs = {
  logs: NotificationLog[];
  hasMore: boolean;
};

@Injectable()
export class NotificationService {
  private readonly baseUrl = `${environment.apiUrl}/api/admin/notification`;

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
  ) {}

  getSegmentNames(): Observable<string[]> {
    const url = `${this.baseUrl}/segment-names`;
    return this.http.get<string[]>(url).pipe();
  }

  getTemplates(): Observable<any[]> {
    const url = `${this.baseUrl}/email/templates`;
    return this.http.get<any[]>(url);
  }

  countUsersForSegment(segmentName: string): Observable<number> {
    const url = `${this.baseUrl}/count-users-segment/${segmentName}`;
    return this.http.get<number>(url);
  }

  getUsersForSegment(
    segmentName: string,
    page: number,
    pageSize: number,
  ): Observable<any> {
    const url = `${this.baseUrl}/users-segment`;
    const body = {
      segment: segmentName,
      pageSize,
      page,
    };
    return this.http.post<any>(url, body);
  }

  sendNotification(
    formValues: any,
    wanted: { email: boolean; web: boolean; push: boolean; sms: boolean },
  ): Observable<number> {
    const url = `${this.baseUrl}/send-notification`;

    if (!formValues) throw new Error('Form values not found');
    if (!wanted) throw new Error('Wanted not found');
    if (!formValues.language) throw new Error('Language not found');
    const languages =
      formValues.language === 'userSettings'
        ? ['en', 'dk']
        : [formValues.language];

    if (!formValues.title) throw new Error('Title not found');
    if (!formValues.segment) throw new Error('Segment not found');
    const sendData: any = {
      title: formValues.title,
      segment: formValues.segment,
      wanted,
      languages,
      scheduledAt:
        formValues.scheduledAt && formValues.scheduledAt.trim() !== ''
          ? new Date(formValues.scheduledAt)
          : null,
    };

    if (!wanted.email && !wanted.web && !wanted.push && !wanted.sms) {
      throw new Error('Select at least one notification channel 🙂');
    }

    if (wanted.email) {
      if (!formValues.emailContext) throw new Error('Email context not found');
      if (!formValues.emailContext.template)
        throw new Error('Template not found');
      sendData.emailContext = {
        template: formValues.emailContext.template,
      };
      languages.forEach((language: string) => {
        const context = formValues.emailContext[language];
        if (!context)
          throw new Error(`Email context for language ${language} not found`);
        sendData.emailContext[language] = context;
      });
    }

    if (wanted.web) {
      if (!formValues.webContext) throw new Error('Web context not found');

      sendData.webContext = {};
      languages.forEach((language: string) => {
        const context = formValues.webContext[language];
        if (!context)
          throw new Error(`Web context for language ${language} not found`);
        const otherParams = formValues.webContext.otherParams || '{}';
        sendData.webContext[language] = {
          ...context,
          ...JSON.parse(otherParams),
        };
      });
    }

    if (wanted.push) {
      if (!formValues.pushContext) throw new Error('Push context not found');

      sendData.pushContext = {};
      languages.forEach((language: string) => {
        const context = formValues.pushContext[language];
        if (!context)
          throw new Error(`Push context for language ${language} not found`);
        const pushData = formValues.pushContext.pushData || 'null';
        sendData.pushContext[language] = {
          ...context,
          pushData: JSON.parse(pushData) || null,
        };
      });
    }

    if (wanted.sms) {
      if (!formValues.smsContext) throw new Error('SMS context not found');
      sendData.smsContext = {};
      languages.forEach((language: string) => {
        const context = formValues.smsContext[language];
        if (!context)
          throw new Error(`SMS context for language ${language} not found`);
        sendData.smsContext[language] = context;
      });
    }

    return this.http.post<number>(url, sendData);
  }

  getFormGroupForTemplate(
    formGroup: UntypedFormGroup,
    template: any,
  ): UntypedFormGroup {
    if (!template) return;

    const en = formGroup.get('en') as UntypedFormGroup;
    const dk = formGroup.get('dk') as UntypedFormGroup;

    if (!en || !dk) throw new Error('Form group not found');

    // Remove all controls (except plainText)
    for (let key of Object.keys(dk.controls)) {
      if (key !== 'plainText') {
        dk.removeControl(key);
        en.removeControl(key);
      }
    }

    // Add controls
    for (let field of template.fields.filter((f) => !f.isDynamic)) {
      const validators: ValidatorFn[] = [Validators.required];
      en.addControl(field.label, new UntypedFormControl('', validators));
      dk.addControl(field.label, new UntypedFormControl('', validators));
    }
  }

  getFormGroup() {
    // error if it's not a date or date is before today
    const dateValidator: ValidatorFn = (control: UntypedFormControl) => {
      if (!control.value) return null;

      const date = new Date(control.value);
      if (date.toString() === 'Invalid Date') return { notADate: true };

      const now = new Date();
      if (date < now) return { dateInPast: true };

      return null;
    };

    const form: UntypedFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      segment: ['', Validators.required],
      language: ['', Validators.required],
      scheduledAt: ['', dateValidator],
      emailContext: this.formBuilder.group({
        template: ['', Validators.required],
        en: this.getFormGroupForEmail(),
        dk: this.getFormGroupForEmail(),
      }),
      webContext: this.formBuilder.group({
        en: this.getFormGroupForWeb(),
        dk: this.getFormGroupForWeb(),
        otherParams: ['', this.isValidJsonValidator()],
      }),
      pushContext: this.formBuilder.group({
        en: this.getFormGroupForPush(),
        dk: this.getFormGroupForPush(),
        pushData: ['', this.isValidJsonValidator()],
      }),
      smsContext: this.formBuilder.group({
        en: this.getFormGroupForSms(),
        dk: this.getFormGroupForSms(),
      }),
    });

    form.get('language')?.setValue('userSettings');
    return form;
  }

  getNotifications(page: number): Observable<ListNotificationSegmentResponse> {
    const url = `${this.baseUrl}/notifications-segment`;
    return this.http.get<ListNotificationSegmentResponse>(url, {
      params: { page },
    });
  }

  getScheduledNotifications(page: number): Observable<{
    notifications: NotificationScheduled[];
    hasMore: boolean;
  }> {
    const url = `${this.baseUrl}/notifications-scheduled`;
    return this.http.get<{
      notifications: NotificationScheduled[];
      hasMore: boolean;
    }>(url, {
      params: { page },
    });
  }

  searchScheduledNotification(id: number): Observable<NotificationScheduled> {
    const url = `${this.baseUrl}/notifications-scheduled/${id}`;
    return this.http.get<NotificationScheduled>(url);
  }

  searchNotification(id: number): Observable<NotificationSegment | null> {
    const url = `${this.baseUrl}/notifications-segment/${id}`;
    return this.http.get<NotificationSegment | null>(url);
  }

  private getFormGroupForEmail(): UntypedFormGroup {
    return this.formBuilder.group({
      plainText: ['', Validators.required],
    });
  }

  private getFormGroupForWeb(): UntypedFormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  private getFormGroupForPush(): UntypedFormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
    });
  }

  private getFormGroupForSms(): UntypedFormGroup {
    const f = this.formBuilder.group({
      message: ['', Validators.required],
    });
    return f;
  }

  private isValidJsonValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (!control.value) return null;

      try {
        if (control.value[0] !== '{') return { invalidJson: true };
        if (control.value[control.value.length - 1] !== '}')
          return { invalidJson: true };

        JSON.parse(control.value);
        return null;
      } catch (e) {
        return { invalidJson: true };
      }
    };
  }

  getLogs(page: number): Observable<ListNotificationLogs> {
    const url = `${this.baseUrl}/logs`;
    return this.http.get<ListNotificationLogs>(url, { params: { page } });
  }

  getNotificationLog(id: number): Observable<NotificationLog> {
    const url = `${this.baseUrl}/logs/${id}`;
    return this.http.get<NotificationLog>(url);
  }

  getNotificationStatistics(): Observable<any> {
    const url = `${this.baseUrl}/get-statistics`;
    return this.http.get<any>(url);
  }

  getNotificationForUser(
    userId: number,
    page: number,
    pageSize: number,
  ): Observable<any> {
    const url = `${this.baseUrl}/user-notifications/${userId}`;
    const params = { page, pageSize };
    return this.http.get<any>(url, { params });
  }
}
