<div class="dialog_container">
  <h2>User: {{ data.userId }}</h2>
  <paginated-list
    [data]="notifications"
    [filterKeys]="filterKeys"
    (displayDataChanged)="updateDisplayNotifications($event)"
    (dataRequested)="fetchNotifications()"
    [moreDataAvailable]="hasMore"
    [isDefaultSort]="true"
    style="width: 100%"
  >
    @for (notification of displayNotifications; track notification.id) {
      <div class="notification">
        <div class="title">
          <mat-icon>notifications</mat-icon>
          <h2>{{ notification.title }}</h2>
        </div>

        <p>{{ notification.message }}</p>
        <div class="more-info">
          <p>{{ notification.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</p>
          <mat-icon
            [ngClass]="{
              read: notification.isRead === true,
              unread: notification.isRead === false,
            }"
            >done_all</mat-icon
          >
        </div>
      </div>
    }
  </paginated-list>
</div>
