<div class="page_container">
  <h1>Requests</h1>
  @if (requests.length == 0) {
    <h2>There are currently no unhandled requests!</h2>
  }

  <mat-form-field>
    <mat-label>Filter requests</mat-label>
    <mat-select
      (selectionChange)="filterRequests($event)"
      [(value)]="selectedType"
    >
      @for (type of types; track type) {
        <mat-option [value]="type.slug">{{ type.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="scroll-wrapper">
    @for (request of shownRequests; track $index) {
      <div class="request">
        <!-- IF THE REQUEST IS A FAILED TAGGING-->
        @if (
          request?.entityType == 'task-tagging-failed' &&
          request &&
          allTags &&
          allTagsWithAssociatedTagCat
        ) {
          <app-request-tagging-failed
            *ngIf="request?.task"
            [allTags]="allTags"
            [allTagsWithAssociatedTagCat]="allTagsWithAssociatedTagCat"
            [task]="request.task"
            [request]="request"
            (tagAddedEvent)="requestHandled($event)"
          ></app-request-tagging-failed>
        }

        <!-- IF THE REQUEST IS A USER MENTIONING MOBILEPAY IN CONVERSATION-->
        @if (request?.entityType == 'user-mentioned-mobile-pay-in-message') {
          <app-request-user-mentioned-mobile-pay
            *ngIf="request?.task"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          ></app-request-user-mentioned-mobile-pay>
        }

        <!-- IF THE REQUEST IS A USER REJECTING THEIR CENSORRING-->
        @if (request?.entityType == 'reject-automatic-censoring') {
          <app-request-rejected-censorring
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          >
          </app-request-rejected-censorring>
        }

        <!-- IF THE REQUEST IS A USER REQUESTING CVR CHECK-->
        @if (request?.entityType == 'pending-company-approval') {
          <app-request-CVR-check
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          >
          </app-request-CVR-check>
        }
        @if (request?.entityType == 'conflict-case-pending-decision') {
          <app-request-conflict-case-pending
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
        @if (request?.entityType == 'payout-failed') {
          <app-request-payout-failed
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
        @if (request?.entityType == 'task-cancellation-denied') {
          <app-request-task-cancellation-denied
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
        @if (request?.entityType == 'multiple-charges') {
          <app-request-multiple-charges
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
        @if (request?.entityType == 'payment-failed') {
          <app-request-payment-failed
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
        @if (request?.entityType == 'new-gift-card') {
          <app-request-new-gift-card
            *ngIf="request"
            [request]="request"
            (requestHandledEvent)="requestHandled($event)"
          />
        }
      </div>
    }
  </div>
</div>
