<!-- IF THE REVIEW WAS GIVEN TO A HANDYHANDER, AND HASNT BEEN CHANGED -->
@if (areObjectsEqual(review, tempReview)) {
  <mat-card
    *ngIf="review.toUserRole == 'pp'"
    matTooltip="Click any star or the tekst to edit"
    [matTooltipPosition]="'above'"
  >
    <div class="card_header">
      <h4>ReviewID: {{ review.id }}</h4>
      <h4>
        Task:
        @if (review.offer?.task) {
          <a
            *ngIf="review.offer?.task"
            href="/tasks/{{ review.offer?.task?.id }}"
            >{{ review.offer.task.title }}</a
          >
        } @else {
          <span>(This task)</span>
        }
      </h4>
      <h4>
        From user:
        <a href="/users/{{ review.fromUser.id }}">
          {{ review.fromUser.firstName }} {{ review.fromUser.lastName }}</a
        >
      </h4>
      <h4>
        Review given:
        <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
      </h4>
    </div>

    <div class="card_body">
      <div class="card_row">
        <h2>Rating</h2>
        <div class="star_container">
          <mat-icon
            class="notranslate star"
            (click)="changeStarRating('rating', i + 1)"
            *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
            [ngClass]="{
              yellow: star <= review.rating,
              gray: star > review.rating,
              star: true,
              large: true,
            }"
            >star_rate</mat-icon
          >
        </div>
      </div>

      <div class="rating_grid">
        <!-- Communication (Grid 1) -->
        <div class="card_row">
          <h3>Communication</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('communication', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= review.communication,
                gray: star > review.communication,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Punctuality (Grid 2)-->
        <div class="card_row">
          <h3>Punctuality</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('punctuality', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= review.punctuality,
                gray: star > review.punctuality,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Eye for detail (Grid 3)-->
        <div class="card_row">
          <h3>Eye for detail</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('eyeForDetail', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= review.eyeForDetail,
                gray: star > review.eyeForDetail,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Effeciency (Grid 4)-->
        <div class="card_row">
          <h3>Effeciency</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('efficiency', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= review.efficiency,
                gray: star > review.efficiency,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>
      </div>
    </div>

    <h3>Message:</h3>
    <div class="message_area">
      <textarea
        id="reviewMessage"
        [(ngModel)]="tempReview.message"
      ></textarea>
    </div>

    <div class="buttons">
      @if (!review.image) {
        <button
          class="pinploy_button small_button"
          (click)="openUploadDocumentDialog()"
        >
          Upload image
        </button>
      }
      <button
        class="pinploy_button small_button red_button"
        (click)="openDeleteReviewDialog()"
      >
        Delete review
      </button>
    </div>

    <div
      class="image_area"
      *ngIf="review.image"
    >
      <div class="tiny_image_container">
        <app-image-preview
          [imageUrl]="review.image.url"
          [fullImageObject]="review.image"
          [tiny]="true"
        ></app-image-preview>
      </div>
    </div>
  </mat-card>
} @else {
  <!-- IF THE REVIEW WAS GIVEN TO A HANDYHANDER, AND HAS BEEN CHANGED -->
  <mat-card
    *ngIf="tempReview.toUserRole == 'pp'"
    class="changed-card"
  >
    <div class="card_header">
      <h4>
        Task:
        <a
          *ngIf="tempReview.offer?.task"
          href="/tasks/{{ tempReview.offer?.task?.id }}"
          >{{ review.offer.task.title }}</a
        >
      </h4>
      <h4>
        From user:
        <a href="/users/{{ tempReview.fromUser.id }}">
          {{ tempReview.fromUser.firstName }}
          {{ tempReview.fromUser.lastName }}</a
        >
      </h4>
      <h4>
        Review given:
        <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
      </h4>
    </div>

    <div class="card_body">
      <div class="card_row">
        <h2>Rating</h2>
        <div class="star_container">
          <mat-icon
            class="notranslate star"
            (click)="changeStarRating('rating', i + 1)"
            *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
            [ngClass]="{
              yellow: star <= tempReview.rating,
              gray: star > tempReview.rating,
              star: true,
              large: true,
            }"
            >star_rate</mat-icon
          >
        </div>
      </div>

      <div class="rating_grid">
        <!-- Communication (Grid 1) -->
        <div class="card_row">
          <h3>Communication</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('communication', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= tempReview.communication,
                gray: star > tempReview.communication,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Punctuality (Grid 2)-->
        <div class="card_row">
          <h3>Punctuality</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('punctuality', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= tempReview.punctuality,
                gray: star > tempReview.punctuality,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Eye for detail (Grid 3)-->
        <div class="card_row">
          <h3>Eye for detail</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('eyeForDetail', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= tempReview.eyeForDetail,
                gray: star > tempReview.eyeForDetail,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>

        <!-- Effeciency (Grid 4)-->
        <div class="card_row">
          <h3>Effeciency</h3>
          <div class="star_container">
            <mat-icon
              class="notranslate"
              (click)="changeStarRating('efficiency', i + 1)"
              *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
              [ngClass]="{
                yellow: star <= tempReview.efficiency,
                gray: star > tempReview.efficiency,
                star: true,
              }"
              >star_rate</mat-icon
            >
          </div>
        </div>
      </div>
    </div>

    <h3>Message:</h3>
    <div class="message_area">
      <textarea
        id="reviewMessage"
        [(ngModel)]="tempReview.message"
        #reviewMessage
      ></textarea>
    </div>

    <div
      class="image_area"
      *ngIf="tempReview.images"
    >
      <div
        *ngFor="let image of tempReview.images"
        class="tiny_image_container"
      >
        <app-image-preview
          [imageUrl]="image.url"
          [tiny]="true"
        ></app-image-preview>
      </div>
    </div>
    <div class="buttons">
      @if (!review.image) {
        <button
          class="pinploy_button small_button"
          (click)="openUploadDocumentDialog()"
        >
          Upload image
        </button>
      }
      <button
        class="pinploy_button small_button"
        (click)="resetReview()"
      >
        Reset review details
      </button>
      <button
        class="pinploy_button small_button green_button"
        (click)="updateReview()"
      >
        Update review details
      </button>
      <button
        class="pinploy_button small_button red_button"
        (click)="openDeleteReviewDialog()"
      >
        Delete review
      </button>
    </div>
  </mat-card>
}

<!-- IF THE REVIEW WAS GIVEN TO A TASK OWNER -->
@if (areObjectsEqual(review, tempReview)) {
  <mat-card
    *ngIf="review.toUserRole == 'to'"
    matTooltip="Click the stars or the tekst to edit"
    [matTooltipPosition]="'above'"
  >
    <div class="card_header">
      <h4>
        Task:
        @if (review.offer?.task) {
          <a
            *ngIf="review.offer?.task"
            href="/tasks/{{ review.offer?.task?.id }}"
            >{{ review.offer.task.title }}</a
          >
        } @else {
          <span>(This task)</span>
        }
      </h4>
      <h4>
        From user:
        <a href="/users/{{ review.fromUser.id }}">
          {{ review.fromUser.firstName }} {{ review.fromUser.lastName }}</a
        >
      </h4>

      <h4>
        Review given:
        <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
      </h4>
    </div>

    <div class="card_body">
      <div class="card_row">
        <h2>Rating</h2>
        <div class="star_container">
          <mat-icon
            class="notranslate"
            (click)="changeStarRating('rating', i + 1)"
            *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
            [ngClass]="{
              yellow: star <= review.rating,
              gray: star > review.rating,
              star: true,
              large: true,
            }"
            >star_rate</mat-icon
          >
        </div>
      </div>
    </div>
    <h3>Message:</h3>
    <div class="message_area">
      <textarea
        id="reviewMessage"
        [(ngModel)]="review.message"
      ></textarea>
    </div>

    <div
      class="image_area"
      *ngIf="review.images"
    >
      <div
        *ngFor="let image of review.images"
        class="tiny_image_container"
      >
        <app-image-preview
          [fullImageObject]="image"
          [tiny]="true"
        ></app-image-preview>
      </div>
    </div>
  </mat-card>
} @else {
  <mat-card
    *ngIf="tempReview.toUserRole == 'to'"
    class="changed-card"
  >
    <div class="card_header">
      <h4>
        Task:
        @if (tempReview.offer?.task) {
          <a
            *ngIf="tempReview.offer?.task"
            href="/tasks/{{ review.offer?.task?.id }}"
            >{{ review.offer.task.title }}</a
          >
        } @else {
          <span>(This task)</span>
        }
      </h4>
      <h4>
        From user:
        <a href="/users/{{ tempReview.fromUser.id }}">
          {{ tempReview.fromUser.firstName }}
          {{ tempReview.fromUser.lastName }}</a
        >
      </h4>
      <h4>
        Review given:
        <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
      </h4>
    </div>

    <div class="card_body">
      <div class="card_row">
        <h2>Rating</h2>
        <div class="star_container">
          <mat-icon
            class="notranslate"
            (click)="changeStarRating('rating', i + 1)"
            *ngFor="let star of [0.5, 1.5, 2.5, 3.5, 4.5]; let i = index"
            [ngClass]="{
              yellow: star <= tempReview.rating,
              gray: star > tempReview.rating,
              star: true,
              large: true,
            }"
            >star_rate</mat-icon
          >
        </div>
      </div>
    </div>
    <h3>Message:</h3>
    <div class="message_area">
      <textarea
        id="reviewMessage"
        [(ngModel)]="tempReview.message"
        #reviewMessage
      ></textarea>
    </div>

    <div
      class="image_area"
      *ngIf="tempReview.images"
    >
      <div
        *ngFor="let image of tempReview.images"
        class="tiny_image_container"
      >
        <app-image-preview
          [fullImageObject]="image"
          [tiny]="true"
        ></app-image-preview>
      </div>
    </div>
    <div class="buttons">
      @if (!review.image) {
        <button
          class="pinploy_button small_button"
          (click)="openUploadDocumentDialog()"
        >
          Upload image
        </button>
      }
      <button
        class="pinploy_button small_button"
        (click)="resetReview()"
      >
        Reset review details
      </button>
      <button
        class="pinploy_button small_button green_button"
        (click)="updateReview()"
      >
        Update review details
      </button>
      <button
        class="pinploy_button small_button red_button"
        (click)="openDeleteReviewDialog()"
      >
        Delete review
      </button>
    </div>
  </mat-card>
}
