import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { type UserLimitations } from '../models/userLimitations.model';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

type GetUsersResponse = {
  data: User[];
  hasMore: boolean;
};

@logClass()
@Injectable()
export class UserService {
  baseHref = `${environment.apiUrl}/api/admin/users`;
  companyBaseHref = `${environment.apiUrl}/api/admin/company`;

  constructor(private http: HttpClient) {}

  getUserNotificationSettings(userId: number): Observable<any> {
    const url = `${this.baseHref}/get-user-notification-settings/${userId}`;
    return this.http.get<any>(url);
  }

  updateUserNotificationSettings(
    userId: number,
    userNotificationSettings: any,
  ): Observable<any> {
    const url = `${this.baseHref}/update-user-notification-settings/${userId}`;
    return this.http.put<any>(url, userNotificationSettings);
  }

  getUsers(page = 1): Observable<GetUsersResponse> {
    const url = `${this.baseHref}`;
    return this.http.get<GetUsersResponse>(url, { params: { page } });
  }

  getUserProfile(id: number): Observable<User> {
    const url = `${this.baseHref}/${id}`;
    return this.http.get<User>(url);
  }

  getPenalties(userId: number): Observable<any> {
    const url = `${this.baseHref}/penalties/${userId}`;
    return this.http.get<any>(url);
  }

  getMediaPortfolio(userId: number): Observable<any> {
    const url = `${this.baseHref}/fetch-portfolio-media/${userId}`;
    return this.http.get<any>(url);
  }

  getMediaHeader(userId: number): Observable<any> {
    const url = `${this.baseHref}/fetch-header-media/${userId}`;
    return this.http.get<any>(url);
  }

  editUser(id: number, data: any): Observable<User> {
    if (data.id) {
      delete data.id;
    }
    if (data.password) {
      delete data.password;
    }
    const url = `${this.baseHref}/${id}`;
    return this.http.put<User>(url, data);
  }

  deleteUser(id: number): Observable<User> {
    const url = `${this.baseHref}/${id}`;
    return this.http.delete<User>(url);
  }

  deleteUserMediaImage(
    data: { id: number; resource: string; resourceId: number },
    notifyUser?: boolean,
  ): Observable<any> {
    const url = `${this.baseHref}/delete-media-image/${data.resource}/${data.id}`;
    return this.http.delete<any>(url, {
      params: { notifyUser: notifyUser, resourceId: data.resourceId },
    });
  }

  sendEmail(
    userId: number,
    template: string,
    taskId: number = null,
  ): Observable<any> {
    const url = `${this.baseHref}/email/${userId}`;
    const data = {
      template: template,
      taskId: taskId,
    };
    return this.http.post<any>(url, data);
  }

  sendSimpleEmail(
    userId: number,
    content: string | string[],
    title: string,
    taskId: number = undefined,
  ): Observable<any> {
    const url = `${this.baseHref}/send-email`;
    const data = {
      userId,
      content,
      title,
      taskId,
    };
    return this.http.post<any>(url, data);
  }

  sendAdminCompletedTaskEmail(userId: number, taskId: number): Observable<any> {
    const url = `${this.baseHref}/send-admin-completed-task-email`;
    const data = { userId, taskId };
    return this.http.post<any>(url, data);
  }

  sendSms(userId: number, message: string): Observable<any> {
    const url = `${this.baseHref}/send-sms`;
    const data = { userId, message };
    return this.http.post<any>(url, data);
  }

  getMultipleUsers(ids: number[]): Observable<User[]> {
    const params = ids.reduce(
      (acc: string, val: number, index: number): string => {
        return `${acc}${index === 0 ? '?' : '&'}id=${val}`;
      },
      '',
    );
    const url = `${environment.apiUrl}/api/users/get-many${params}`;
    return this.http.get<User[]>(url);
  }

  removeProfilePictureAndNotify(
    userId: number,
    notify: boolean,
  ): Observable<any> {
    const url = `${this.baseHref}/remove-profile-pic/${userId}`;
    return this.http.delete<any>(url, { params: { notifyUser: notify } });
  }

  getUserLimitations(userId: number): Observable<UserLimitations> {
    const url = `${this.baseHref}/limitations/${userId}`;
    return this.http.get<UserLimitations>(url);
  }

  getUserNotes(userId: number, page: number, all?: boolean): Observable<any> {
    const url = `${this.baseHref}/${userId}/notes`;
    const query = {
      page,
      all: all ? 'true' : 'false',
    };
    return this.http.get<any>(url, { params: query });
  }

  createUserNote(
    userId: number,
    note: string,
    additionalInfo?: object,
  ): Observable<any> {
    const url = `${this.baseHref}/${userId}/notes`;
    const data = {
      note,
      additionalInfo,
    };
    return this.http.post<any>(url, data);
  }

  deleteUesrNoteById(userId: number, noteId: number): Observable<any> {
    const url = `${this.baseHref}/${userId}/notes/${noteId}`;
    return this.http.delete<any>(url);
  }

  getUserReviews(
    userId: number,
    role: string,
    page: number,
    all?: boolean,
  ): Observable<any> {
    const url = `${this.baseHref}/${userId}/reviews`;
    const query = {
      role,
      page,
      all: all ? 'true' : 'false',
    };
    return this.http.get<any>(url, { params: query });
  }

  resetPassword(userId: number): Observable<any> {
    const url = `${this.baseHref}/${userId}/generate-new-password`;
    return this.http.get<any>(url);
  }

  giveGracePeriod(
    userId: number,
    type: 'ban' | 'handyhander-limitations',
    until: string,
  ): Observable<any> {
    const url = `${this.baseHref}/give-grace-period`;
    const data = {
      userId,
      type,
      until,
    };
    return this.http.post<any>(url, data);
  }

  removeGracePeriod(
    userId: number,
    type: 'ban' | 'handyhander-limitations',
  ): Observable<any> {
    const url = `${this.baseHref}/remove-grace-period`;
    const data = {
      userId,
      type,
    };
    return this.http.post<any>(url, data);
  }

  mergeUsers(sourceUserId: number, targetUserId: number): Observable<any> {
    const url = `${this.baseHref}/merge`;
    const body = {
      sourceUserId,
      targetUserId,
      stripeCustomerToKeep: 'source',
    };

    return this.http.post<any>(url, body);
  }

  updateUserLimitations(userId: number): Observable<any> {
    const url = `${this.baseHref}/update-user-ratings/${userId}`;
    return this.http.get<any>(url);
  }

  getLastActivity(userId: number): Observable<any> {
    const url = `${this.baseHref}/last-user-activity/${userId}`;
    return this.http.get<any>(url);
  }

  updateReview(review: any): Observable<any> {
    const url = `${this.baseHref}/update-review/${review.id}`;
    return this.http.put<any>(url, review);
  }

  deleteReview(reviewId: number): Observable<any> {
    const url = `${this.baseHref}/delete-review/${reviewId}`;
    return this.http.delete<any>(url);
  }

  updateCVRCheckStatus(
    cvrNumber: number,
    userId: number,
    status: string,
  ): Observable<any> {
    const data = {
      cvrNumber,
      userId,
      status,
    };

    const url = `${this.companyBaseHref}/update-status`;
    return this.http.put<any>(url, data);
  }
}
