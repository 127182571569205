<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>Task Cancellation Denied</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="task-cancellation-info">
          <a [routerLink]="['/cancel-task', cancelTask.id]">Link</a>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
