<h2 mat-dialog-title>User notes</h2>
<mat-dialog-content>
  @for (note of userNotes; track $index) {
    <div>
      <app-user-note-card [noteInput]="note"></app-user-note-card>
    </div>
  } @empty {
    <p>No user notes</p>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>