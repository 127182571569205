import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { Report } from '../models/report.model';
import {
  SupportTicket,
  SupportTicketMessage,
  SupportTicketReminder,
  SupportTicketStatuses,
} from '../models/supportTicket.model';
import { AdminActionRequest } from '../models/adminActionRequest.model';
import { type CurrentlyViewedAdminEntities } from './socket.service';
interface ReportRead {
  targetId: number;
  type: 'task_comment' | 'offer' | 'offer_comment' | 'task' | string;
}

@Injectable()
export class StateService {
  constructor() {
    this.initcurrentlyViewedEntitiesOverviewSource();
  }
  // Used to toggle a 'waiting state' that the platform is in
  public toggleWaitingComponentSource = new Subject<boolean>();

  //used for websockets for reports
  public totalActiveReportsComponentSource = new Subject<any>();
  public readActiveReportsComponentSource = new Subject<any>();

  //used for websockets for support tickets
  public totalActiveSupportTicketsComponentSource = new Subject<any>();
  public readActiveSupportTicketsComponentSource = new Subject<any>();

  public unresolvedSupportTicketsCountSource = new Subject<number>();
  public updateUnresolvedSupportTicketsCount(count: number) {
    this.unresolvedSupportTicketsCountSource.next(count);
  }

  public supportTicketReminderCreatedSource = new Subject<any>();
  public supportTicketReminderCreated(
    reminder: SupportTicketReminder,
    supportTicket: SupportTicket,
  ) {
    this.supportTicketReminderCreatedSource.next({ reminder, supportTicket });
  }
  public supportTicketReminderCompletedSource = new Subject<any>();

  public newSupportTicketSource = new Subject<SupportTicket>();
  public newSupportTicket(supportTicket: SupportTicket) {
    this.newSupportTicketSource.next(supportTicket);
  }
  public newSupportTicketMessageSource = new Subject<SupportTicket>();
  public newSupportTicketMessage(supportTicket: SupportTicket) {
    this.newSupportTicketMessageSource.next(supportTicket);
  }

  public supportTicketUpdatedSource = new Subject<SupportTicket>();
  public supportTicketUpdated(supportTicket: SupportTicket) {
    this.supportTicketUpdatedSource.next(supportTicket);
  }

  public supportTicketClosedSource = new Subject<
    { supportTicket: SupportTicket; oldStatus: SupportTicketStatuses }
  >();
  public supportTicketClosed({
    supportTicket,
    oldStatus,
  }: {
    supportTicket: SupportTicket;
    oldStatus: SupportTicketStatuses;
  }) {
    this.supportTicketClosedSource.next({ supportTicket, oldStatus });
  }

  //used for websockets for admin action requests
  public totalActiveAdminActionRequestsComponentSource =
    new Subject<AdminActionRequest>();

  public readActiveAdminActionRequestsComponentSource =
    new Subject<AdminActionRequest>();

  public currentlyViewedSupportTicketsSource = new BehaviorSubject<
    CurrentlyViewedAdminEntities['support_ticket']
  >(null);

  public currentlyViewedReportsSource = new BehaviorSubject<
    CurrentlyViewedAdminEntities['report']
  >(null);

  public currentlyViewedEntitiesOverviewSource =
    new Subject<CurrentlyViewedAdminEntities>();
  initcurrentlyViewedEntitiesOverviewSource() {
    combineLatest([
      this.currentlyViewedSupportTicketsSource,
      this.currentlyViewedReportsSource,
    ])
      .pipe(map(([support_ticket, report]) => ({ support_ticket, report })))
      .subscribe(this.currentlyViewedEntitiesOverviewSource);
  }

  public onlineAdminsSource = new BehaviorSubject<any>(null);

  public updateOnlineAdmins(admins: any) {
    this.onlineAdminsSource.next(admins);
  }

  public updateCurrentlyViewedSupportTickets(
    supportTicket: CurrentlyViewedAdminEntities['support_ticket'],
  ) {
    this.currentlyViewedSupportTicketsSource.next(supportTicket);
  }

  public updateCurrentlyViewedReports(
    report: CurrentlyViewedAdminEntities['report'],
  ) {
    this.currentlyViewedReportsSource.next(report);
  }

  public toggleWaitingPage(status: boolean) {
    this.toggleWaitingComponentSource.next(status);
  }

  public updateTotalActiveReports(report: Report) {
    this.totalActiveReportsComponentSource.next(report);
  }

  public readActiveReports(
    targetId: number,
    type: 'task_comment' | 'offer' | 'offer_comment' | 'task' | string,
  ) {
    this.readActiveReportsComponentSource.next({ targetId, type });
  }

  public updateTotalActiveSupportTickets(supportTicket: SupportTicket) {
    this.totalActiveSupportTicketsComponentSource.next(supportTicket);
  }

  public readActiveSupportTickets(supportTicket: SupportTicket) {
    this.readActiveSupportTicketsComponentSource.next(supportTicket);
  }

  public updateTotalActiveAdminActionRequests(
    adminActionRequest: AdminActionRequest,
  ) {
    this.totalActiveAdminActionRequestsComponentSource.next(adminActionRequest);
  }

  public readActiveAdminActionRequests(adminActionRequest: AdminActionRequest) {
    this.readActiveAdminActionRequestsComponentSource.next(adminActionRequest);
  }
}
