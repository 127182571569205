<h2 matDialogTitle>Smart responses</h2>
<mat-dialog-content>
  <div class="subtitle">
    Choose a standard response for common issues below. Select a response and
    customize as needed. You can also generate a response using AI
  </div>
  <mat-divider></mat-divider>
  <div class="content-container">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Language</mat-label>
        <mat-select [(value)]="language">
          <mat-option value="danish">Danish</mat-option>
          <mat-option value="english">English</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-tab-group dynamicHeight>
        <mat-tab label="Suggested">
          @if (allPrioritizedEmailTemplates.length > 0) {
          <div class="templates-container">
            @if (language === 'danish') { @for (template of
            dkPrioritizedEmailTemplates; track $index) {
            <div
              class="template-item"
              (click)="selectAndInsertEmailTemp(template)"
            >
              <p>{{ template.question }}</p>
              <mat-icon>add</mat-icon>
            </div>
            } @empty {
            <p>No email templates found</p>
            } } @if (language === 'english') { @for (template of
            engPrioritizedEmailTemplates; track $index) {
            <div
              class="template-item"
              (click)="selectAndInsertEmailTemp(template)"
            >
              <p>{{ template.question }}</p>
              <mat-icon>add</mat-icon>
            </div>
            } @empty {
            <p>No email templates found</p>
            } }
          </div>
          }
        </mat-tab>
        <mat-tab label="All">
          <div class="all-templates-header">
            <mat-icon>lists</mat-icon>
            <h4>Categories</h4>
          </div>
          <mat-accordion>
            <div class="all-templates-content">
              @for (category of emailTemplateCategories; track $index) {
              <mat-expansion-panel (click)="selectEmailTempCategory(category)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    @if (language === 'danish') {
                    {{ category.dkName }}
                    } @if (language === 'english') {
                    {{ category.enName }}
                    }
                  </mat-panel-title>
                </mat-expansion-panel-header>
                @if (language === 'danish') {
                <div class="template-list">
                  @for (template of filteredEmailTemplates?.dk; track $index) {
                  <div
                    class="template-item"
                    (click)="selectAndInsertEmailTemp(template)"
                  >
                    <p>{{ template.question }}</p>
                    <mat-icon>add</mat-icon>
                  </div>
                  }
                </div>
                } @if (language === 'english') {
                <div class="template-list">
                  @for (template of filteredEmailTemplates?.eng; track $index) {
                  <div
                    class="template-item"
                    (click)="selectAndInsertEmailTemp(template)"
                  >
                    <p>{{ template.question }}</p>
                    <mat-icon>add</mat-icon>
                  </div>
                  }
                </div>
                }
              </mat-expansion-panel>
              }
            </div>
          </mat-accordion>
        </mat-tab>
        <mat-tab label="AI"> </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"> </mat-dialog-actions>
