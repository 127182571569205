<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>Payment Failed</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="payment-failed-info">
          <div>
            <p>User {{ userId }} has trouble with payment</p>
            <p>
              Two payments have failed for:
              {{ failedCharges[0].amount / 100 }} kr.
            </p>
          </div>
          <div>
            <p>Links</p>
            @for (link of links; track $index) {
              <a href="{{ link.url }}">{{ link.text }}</a>
            }
          </div>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
