<div
  class="navbar_container transition"
  [ngClass]="{
    hidden: hovered === false,
    mat_badge_width:
      activeSupportTicketsCount >= 1 ||
      activeReportsCount >= 1 ||
      activeRequestsCount >= 1,
  }"
  (mouseenter)="hover()"
  (mouseleave)="unhover()"
>
  <div *ngFor="let item of items; let i = index">
    <div
      class="nav_item"
      [ngClass]="{ active: activeTab === item.name, hidden: true }"
      (click)="goTo(item.route)"
    >
      <div class="name_and_icon">
        @if (item.name === 'Support Tickets') {
          <h4 *ngIf="unresolvedSupportTicketsCount >= 1">
            <mat-icon
              [matBadge]="unresolvedSupportTicketsCount"
              matBadgeColor="warn"
              >{{ item.icon }}</mat-icon
            >
            <p
              [ngClass]="{
                shown: hovered === true,
                not_shown: hovered === false,
              }"
            >
              {{ item.name }}
            </p>
          </h4>

          <h4 *ngIf="unresolvedSupportTicketsCount <= 0">
            <mat-icon>{{ item.icon }}</mat-icon>
            <p
              [ngClass]="{
                shown: hovered === true,
                not_shown: hovered === false,
              }"
            >
              {{ item.name }}
            </p>
          </h4>
        }

        <h4
          *ngIf="
            item.name == 'Users' && activeReportsCount >= 1 && !item.opened
          "
        >
          <mat-icon
            matBadge="{{ activeReportsCount }}"
            matBadgeColor="warn"
            >{{ item.icon }}</mat-icon
          >
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>
        <h4
          *ngIf="item.name == 'Users' && activeReportsCount >= 1 && item.opened"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>
        <h4 *ngIf="item.name == 'Users' && activeReportsCount <= 0">
          <mat-icon>{{ item.icon }}</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>

        <h4
          *ngIf="
            item.name == 'Conflict Cases' &&
            conflictCasesRequireAttention.length >= 1
          "
        >
          <mat-icon
            matBadge="{{ conflictCasesRequireAttention.length }}"
            matBadgeColor="warn"
            >{{ item.icon }}</mat-icon
          >

          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>
        <h4
          *ngIf="
            item.name == 'Conflict Cases' &&
            conflictCasesRequireAttention.length <= 0
          "
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>

        <h4 *ngIf="item.name == 'Requests' && activeRequestsCount >= 1">
          <mat-icon
            matBadge="{{ activeRequestsCount }}"
            matBadgeColor="warn"
            >{{ item.icon }}</mat-icon
          >
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>

        <h4 *ngIf="item.name == 'Requests' && !activeRequestsCount">
          <mat-icon>{{ item.icon }}</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>

        <h4
          *ngIf="
            item.name != 'Support Tickets' &&
            item.name != 'Conflict Cases' &&
            item.name != 'Users' &&
            item.name != 'Requests'
          "
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            {{ item.name }}
          </p>
        </h4>
      </div>

      <div
        class="drop_down_arrows"
        *ngIf="item.subitems"
        [ngClass]="{
          shown: hovered === true,
          not_shown: hovered === false,
        }"
      >
        <mat-icon
          class="pointer"
          *ngIf="!item.opened && item.subitems"
          (click)="openItemMenu(items[i], $event)"
          >arrow_drop_down</mat-icon
        >
        <mat-icon
          class="pointer"
          *ngIf="item.opened && item.subitems"
          (click)="openItemMenu(items[i], $event)"
          >arrow_drop_up</mat-icon
        >
      </div>
    </div>

    <div *ngIf="item.opened && item.subitems">
      <div
        class="nav_item sub"
        [ngClass]="{ active: activeTab === subitem.name }"
        *ngFor="let subitem of item.subitems; let j = index"
        (click)="goTo(subitem.route)"
      >
        <div class="{ 'active': activeTab === subitem.name }">
          <h4 *ngIf="activeReportsCount <= 0 && subitem.name == 'Reports'">
            <mat-icon>{{ subitem.icon }}</mat-icon
            >{{ subitem.name }}
          </h4>
          <h4 *ngIf="activeReportsCount >= 1 && subitem.name == 'Reports'">
            <mat-icon
              matBadge="{{ activeReportsCount }}"
              matBadgeColor="warn"
              >{{ subitem.icon }}</mat-icon
            >{{ subitem.name }}
          </h4>
          <h4 *ngIf="subitem.name !== 'Reports'">
            <mat-icon>{{ subitem.icon }}</mat-icon
            >{{ subitem.name }}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div style="flex-direction: column">
    <div
      class="nav_item"
      [ngClass]="{ hidden: hovered === false, active: activeTab === 'Misc' }"
    >
      <div class="name_and_icon">
        <h4>
          <mat-icon>more_horiz</mat-icon>
          <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
          >
            Misc
          </p>
        </h4>
      </div>

      <div
        class="drop_down_arrows"
        (click)="openMiscItemMenu()"
        [ngClass]="{
          shown: hovered === true,
          not_shown: hovered === false,
        }"
      >
        <mat-icon
          *ngIf="miscItemsOpened"
          class="pointer"
          >arrow_drop_up</mat-icon
        >
        <mat-icon
          *ngIf="!miscItemsOpened"
          class="pointer"
          >arrow_drop_down</mat-icon
        >
      </div>
    </div>

    <div *ngFor="let item of miscItems; let j = index">
      <div
        class="nav_item sub"
        *ngIf="miscItemsOpened"
        (click)="goTo(item.route)"
        [ngClass]="{ active: activeTab === item.name }"
      >
        <div>
          <div>
            <h4>
              <mat-icon>{{ item.icon }}</mat-icon>

              {{ item.name }}
            </h4>
          </div>
        </div>

        <div
          (click)="openItemMenu(item, $event)"
          class="drop_down_arrows"
          *ngIf="item.subitems"
        >
          <mat-icon
            class="pointer"
            *ngIf="!item.opened && item.subitems"
            >arrow_drop_down</mat-icon
          >
          <mat-icon
            class="pointer"
            *ngIf="item.opened && item.subitems"
            >arrow_drop_up</mat-icon
          >
        </div>
      </div>

      <div *ngIf="item.opened && item.subitems">
        <div
          class="nav_item subsub"
          [ngClass]="{ active: activeTab === subitem.name }"
          *ngFor="let subitem of item.subitems"
          (click)="goTo(subitem.route)"
        >
          <div class="{ 'active': activeTab === item.id }">
            <h4>
              <mat-icon>{{ subitem.icon }}</mat-icon
              >{{ subitem.name }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
