<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>New Gift Card</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="gift-card-info">
          <div>
            <p>Check completed payment:</p>
            <a
              target="_blank"
              matTooltip="Open payment in Stripe"
              [href]="[
                'https://dashboard.stripe.com/payments/',
                giftCardData.payment_intent,
              ]"
              >Payment link</a
            >
          </div>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
