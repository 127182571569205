import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { SupportTicket } from 'src/app/models/supportTicket.model';

export interface SupportTicketsDialogData {
  openSupportTickets: SupportTicket[];
  closedSupportTickets: SupportTicket[];
}
@Component({
  selector: 'support-tickets-dialog',
  templateUrl: 'supportTicketsDialog.component.html',
  styleUrls: ['../dialogs.scss', 'supportTicketsDialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    RouterLink,
    MatTooltipModule,
  ],
})
export class SupportTicketsDialogComponent implements OnInit, OnDestroy {
  openSupportTickets: SupportTicket[] = [];
  closedSupportTickets: SupportTicket[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupportTicketsDialogData,
    private dialogRef: MatDialogRef<SupportTicketsDialogComponent>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.openSupportTickets = this.data.openSupportTickets;
    this.closedSupportTickets = this.data.closedSupportTickets;
  }

  openTicket($event: MouseEvent, uid: string): Promise<boolean> {
    if ($event.ctrlKey || $event.metaKey) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['support-tickets-v2'], {
          queryParams: { uid },
        }),
      );
      window.open(url, '_blank');
    } else {
      this.closeDialog();
      return this.router.navigate(['support-tickets-v2'], {
        queryParams: { uid },
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }
}
