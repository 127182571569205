<div class="support-admin-panel">
  <mat-sidenav-container
    autosize
    style="height: 100%"
  >
    <mat-sidenav-content style="display: flex; overflow: hidden">
      <!-- Ticket List -->
      <div class="ticket-list">
        <div class="tab_bar_container">
          <!-- Tab Bar -->
          <div>
            <mat-tab-group
              dynamicHeight
              (selectedTabChange)="onTabChange($event)"
              [(selectedIndex)]="selectedTabIndex"
            >
              <mat-tab
                #openStatusTab
                label="Open"
              >
                <ng-template mat-tab-label>
                  <span>Open</span>
                </ng-template></mat-tab
              >
              <mat-tab label="Closed"></mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <!-- TODO: ticket / ticket list component -->
        <div
          #supportTicketListContainer
          (scroll)="onSupportTicketListScroll($event)"
          class="list-container"
        >
          <ul>
            @for (
              ticket of currentTab === 'open' ? openTickets : closedTickets;
              track $index
            ) {
              <!-- TODO: <app-support-ticket-v2 /> -->
              <li
                style="position: relative"
                class="ticket"
                [class.selected]="ticket === selectedTicket"
                [class.unresolved]="ticket.status === 'unresolved'"
                (click)="onTicketListClick($event, ticket)"
              >
                <div class="status_person">
                  <p
                    [class.status-unresolved]="ticket.status === 'unresolved'"
                    [class.status-resolved]="ticket.status === 'resolved'"
                    [class.status-closed]="ticket.status === 'closed'"
                    class="status_ticket"
                  >
                    {{ ticket.status | uppercase }}
                  </p>
                </div>
                <div>
                  <p>
                    <b>{{ ticket.mainConcern }}</b>
                  </p>
                  <p>
                    {{
                      ticket?.messages?.slice().reverse().at(-1)?.text || ''
                        | striphtml
                    }}
                  </p>
                  <p>{{ ticket.createdAt | date }}</p>
                </div>
                <div style="position: absolute; right: 0; top: 0">
                  <!-- TODO: needs fitting styling for list view -->
                  <app-users-currently-viewing
                    [usersCurrentlyViewing]="ticket.viewedBy"
                  />
                </div>
              </li>
            } @empty {}
          </ul>
        </div>

        @if (loading) {
          <div class="loading-spinner"></div>
        }
      </div>
      <!-- TODO: ticket messages component? -->
      <!-- Conversation Thread -->
      <div class="conversation-thread">
        <div
          class="messages_container"
          #selectedTicketMessagesContainer
        >
          @if (selectedTicket) {
            @for (
              message of selectedTicket?.messages?.slice().reverse();
              track $index
            ) {
              <!-- <app-support-ticket-message /> -->
              <div
                class="message_container"
                [class.user-message]="
                  !message?.createdBy ||
                  message?.createdBy === selectedTicket.userId
                "
              >
                <div class="user-bubble">
                  @if (!message?.createdBy) {
                    <mat-icon [matTooltip]="selectedTicket?.email"
                      >person</mat-icon
                    >
                  } @else if (message?.createdBy === selectedTicket.userId) {
                    <mat-icon [matTooltip]="selectedTicket?.user?.firstName"
                      >person</mat-icon
                    >
                  } @else {
                    <mat-icon matTooltip="Handyhand Support"
                      >support_agent</mat-icon
                    >
                  }
                </div>
                <div class="message">
                  <div class="header">
                    @if (!message.createdBy) {
                      <i>{{ selectedTicket?.email }}</i>
                    } @else if (message?.createdBy === selectedTicket.userId) {
                      <i>{{ selectedTicket?.user?.firstName }}</i>
                    } @else {
                      <i>{{ message?.user?.firstName }}</i>
                    }
                    <p class="time_stamp">
                      {{ message.createdAt | date: 'd/M/yy, H:mm' }}
                    </p>
                  </div>
                  <mat-divider />
                  <div
                    class="message-text"
                    [innerHTML]="message.text | safeHtml"
                  >
                    <!-- {{ message.text }} -->
                  </div>
                  @if (message?.images?.length > 0) {
                    <mat-divider></mat-divider>
                    <div class="attached-files">
                      @for (image of message.images; track $index) {
                        <div class="image">
                          <div
                            matTooltip="Open image in new tab"
                            class="item"
                            (click)="openPreviewImage(image)"
                          >
                            <mat-icon>image</mat-icon>
                            <p>{{ image.name }}</p>
                          </div>
                          <div
                            matTooltip="Download image"
                            class="item"
                            (click)="downloadFile(image)"
                          >
                            <mat-icon>download</mat-icon>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  @if (message?.docs?.length > 0) {
                    <div class="attached-files">
                      @for (doc of message.docs; track $index) {
                        <div class="image">
                          <div class="item">
                            <mat-icon>file_present</mat-icon>
                            <p>{{ doc.name }}</p>
                          </div>
                          <div
                            class="item"
                            (click)="downloadFile(doc)"
                          >
                            <mat-icon>download</mat-icon>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            } @empty {
              <div class="message_container">wow such empty</div>
            }
          }
        </div>
        @if (messageForm.get('images').value) {
          <div class="attached-files">
            @for (image of messageForm.get('images').value; track $index) {
              <div class="image">
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  (click)="openPreviewImage(image)"
                >
                  <mat-icon>image</mat-icon>
                  <p>{{ image.originalname }}</p>
                </div>
                <div
                  style="display: flex; cursor: pointer"
                  (click)="removePreviewImage(image)"
                >
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            }
          </div>
        }
        @if (messageForm.get('docs').value) {
          <div class="attached-files">
            @for (doc of messageForm.get('docs').value; track $index) {
              <div class="image">
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                >
                  <mat-icon>image</mat-icon>
                  <p>{{ doc.originalname }}</p>
                </div>
                <div
                  style="display: flex; cursor: pointer"
                  (click)="removePreviewDoc(doc)"
                >
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            }
          </div>
        }
        <div class="reply-box">
          <div
            #editorToolbar
            id="quill-toolbar"
            class="quill-toolbar"
          >
            <span class="ql-formats">
              <select class="ql-header"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              ></button>
              <button
                class="ql-list"
                value="bullet"
              ></button>
              <button
                class="ql-indent"
                value="-1"
              ></button>
              <button
                class="ql-indent"
                value="+1"
              ></button>
            </span>
            <span class="ql-formats">
              <button class="ql-link"></button>
            </span>
            <span class="ql-formats">
              <button
                (click)="imagesPicker.click()"
                id="imageButton"
              >
                <svg viewBox="0 0 18 18">
                  <rect
                    class="ql-stroke"
                    height="10"
                    width="12"
                    x="3"
                    y="4"
                  ></rect>
                  <circle
                    class="ql-fill"
                    cx="6"
                    cy="7"
                    r="1"
                  ></circle>
                  <polyline
                    class="ql-even ql-fill"
                    points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
                  ></polyline>
                </svg>
              </button>
              <button
                (click)="docsPicker.click()"
                id="docButton"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g
                    id="SVGRepo_bgCarrier"
                    stroke-width="0"
                  ></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill="#000000"
                      d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm320 512V448h64v128h128v64H544v128h-64V640H352v-64h128z"
                    ></path>
                  </g>
                </svg>
              </button>
            </span>
            <span class="ql-formats">
              <div
                (click)="openSmartResponses()"
                class="custom-buttons"
              >
                <mat-icon>star</mat-icon>
                <button
                  id="smart-response"
                  class="button"
                >
                  Smart responses
                </button>
              </div>
            </span>
            <span class="ql-formats">
              <div
                (click)="sendMessage(selectedTicket.id)"
                class="custom-buttons"
              >
                <mat-icon>send</mat-icon>
                <button class="button">
                  <p>Send</p>
                </button>
              </div>
            </span>
          </div>
          <div
            #editor
            class="html-editor"
          ></div>
          <input
            accept="image/*"
            multiple
            style="display: none"
            type="file"
            #imagesPicker
            (change)="onImagesPicked($event)"
          />
          <input
            accept=".doc,.docx,.pdf"
            multiple
            style="display: none"
            type="file"
            #docsPicker
            (change)="onDocumentsPicked($event)"
          />
        </div>
      </div>
      <!-- Toggle Button Container -->
      <div class="toggle-wrapper">
        <button
          matTooltip="Ctrl + B to toggle"
          class="toggle-button"
          (click)="sidenav.toggle()"
        >
          <mat-icon>{{
            detailsSidebarOpen ? 'chevron_right' : 'chevron_left'
          }}</mat-icon>
        </button>
      </div>
    </mat-sidenav-content>
    <mat-sidenav
      #sidenav
      mode="side"
      [(opened)]="detailsSidebarOpen"
      position="end"
    >
      @if (selectedTicket) {
        <!-- TODO: ticket details component? -->
        <!-- Ticket Details -->
        <div class="ticket-details">
          <mat-tab-group>
            <!-- Details Tab -->
            <mat-tab label="Details">
              <div class="details-tab">
                @if (selectedTicket?.overdueReminders?.length > 0) {
                  <div>
                    <h4>Overdue reminders</h4>
                    <ul style="list-style: none">
                      @for (
                        reminder of selectedTicket.overdueReminders;
                        track $index
                      ) {
                        <li>
                          <div class="reminder">
                            <div class="name_checkbox">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  width: 100%;
                                  align-items: center;
                                "
                              >
                                <div>
                                  <mat-checkbox
                                    [color]="'primary'"
                                    color="primary"
                                    (change)="
                                      completeSupportTicketReminder(
                                        $event,
                                        reminder?.id
                                      )
                                    "
                                  ></mat-checkbox>
                                </div>
                                <div>
                                  <p>By: {{ reminder?.user?.firstName }}</p>
                                </div>
                                <div>
                                  <p>
                                    Expires:
                                    {{
                                      reminder?.expiresAt | date: 'dd/MM/YYYY'
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style="cursor: pointer"
                              (click)="openReminderDialog(reminder)"
                            >
                              <p
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ reminder?.note }}
                              </p>
                              <a style="color: #009ac6">Read more</a>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                }

                <div class="button_wrapper">
                  <button
                    class="pinploy_button small_button icon_plus_text"
                    [matMenuTriggerFor]="menu"
                    #menuTrigger
                  >
                    <p>More options</p>
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>

                  <mat-menu
                    #menu="matMenu"
                    xPosition="before"
                  >
                    <a
                      mat-menu-item
                      [disabled]="!selectedTicket?.userId"
                      (click)="openStoredCreditsDialog()"
                    >
                      <mat-icon>savings</mat-icon>
                      <span>Stored Credits</span>
                    </a>
                    <a
                      mat-menu-item
                      [disabled]="
                        selectedTicket?.user?.storedCreditsBalance <= 0
                      "
                      (click)="openWithdrawStoredCreditsDialog()"
                    >
                      <mat-icon>money_off</mat-icon>
                      <span>Withdraw Stored Credits</span>
                    </a>
                    <a
                      mat-menu-item
                      [disabled]="!selectedTicket?.userId"
                      (click)="openBansOverviewDialog()"
                    >
                      <mat-icon>gavel</mat-icon>
                      <span> Bans </span>
                    </a>
                    <a
                      mat-menu-item
                      [disabled]="!selectedTicket?.userId"
                      (click)="openNotesOverviewDialog()"
                    >
                      <mat-icon>sticky_note_2</mat-icon>
                      <span> Notes </span>
                    </a>
                    <a
                      mat-menu-item
                      [disabled]="!userHasPenalties"
                      (click)="openPenaltiesDialog()"
                    >
                      <mat-icon>error</mat-icon>
                      <span> Penalties </span>
                    </a>

                    @if (selectedTicket?.status === 'unresolved') {
                      <a
                        mat-menu-item
                        (click)="markAsResolved(selectedTicket.id)"
                      >
                        <mat-icon style="color: #26733f">check_circle</mat-icon>
                        <span>Mark as Resolved</span>
                      </a>
                    }

                    <a
                      mat-menu-item
                      (click)="closeTicket(selectedTicket.id)"
                      [disabled]="selectedTicket.status === 'closed'"
                    >
                      <mat-icon style="color: red">cancel</mat-icon>
                      <span style="color: red">Close ticket</span>
                    </a>
                  </mat-menu>
                </div>

                <div class="detail_sub">
                  <h4>Ticket</h4>
                  <div>
                    <p class="first">Ticket ID</p>
                    <p class="last">
                      <b>{{ selectedTicket.id }}</b>
                    </p>
                  </div>
                  @if (selectedTicket.uid) {
                    <div>
                      <p class="first">Ticket UID</p>
                      <p class="last">
                        <b>{{ selectedTicket.uid }}</b>
                      </p>
                    </div>
                  }
                  <div>
                    <p class="first">Created</p>
                    <p class="last">
                      <b>{{ selectedTicket.createdAt | date }}</b>
                    </p>
                  </div>
                  <div>
                    <p class="first">Type</p>
                    <p class="last">
                      <b>{{ selectedTicket.topic }}</b>
                    </p>
                  </div>
                  @if (!selectedTicket.user) {
                    <div>
                      <p class="first">Email</p>
                      <p class="last">
                        <b>{{ selectedTicket.email }}</b>
                      </p>
                    </div>
                  }
                </div>

                @if (selectedTicket.user) {
                  <div class="detail_sub">
                    <div>
                      <h4>User</h4>
                      <p>
                        <b
                          ><a
                            [routerLink]="['../users', selectedTicket.user.id]"
                            >View user profile</a
                          ></b
                        >
                      </p>
                    </div>
                    <div>
                      <p class="first">User ID</p>
                      <p class="last">
                        <b>{{ selectedTicket?.userId }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Name</p>
                      <p class="last">
                        <b>{{ selectedTicket.user.firstName }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Email</p>
                      <p class="last">
                        <b>
                          {{ selectedTicket.email }}
                        </b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Phone</p>
                      <p class="last">
                        <b>
                          {{ selectedTicket.user.phone }}
                        </b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Stripe Customer ID</p>
                      <p>
                        <b>
                          <a
                            href="https://dashboard.stripe.com/customers/{{
                              selectedTicket.user.stripeCustomerId
                            }}"
                            target="_blank"
                            >{{ selectedTicket.user.stripeCustomerId }}</a
                          >
                        </b>
                      </p>
                    </div>
                  </div>
                } @else {
                  <div class="detail_sub">
                    <div>
                      <h4>User</h4>
                    </div>
                    <p>No user associated with this ticket.</p>
                    <mat-form-field>
                      <input
                        #userIdInput
                        matInput
                        placeholder="Enter user ID"
                        type="text"
                      />
                    </mat-form-field>
                    <button
                      [class.disabled]="!userIdInput.value"
                      [disabled]="!userIdInput.value"
                      class="pinploy_button"
                      (click)="onConnectUserToTicket(userIdInput.value)"
                    >
                      Connect ticket to user
                    </button>
                  </div>
                }
                @if (selectedTicket.task) {
                  <div class="detail_sub">
                    <div>
                      <h4>Task</h4>
                      <p>
                        <b
                          ><a
                            [routerLink]="['../tasks', selectedTicket.task.id]"
                            >View task</a
                          ></b
                        >
                      </p>
                    </div>
                    <div>
                      <p class="first">Task ID</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.id }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Title</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.title }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Budget</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.budget }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Status</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.status }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">Owner</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.userId }}</b>
                      </p>
                    </div>
                    <div>
                      <p class="first">City</p>
                      <p class="last">
                        <b>{{ selectedTicket.task.city }}</b>
                      </p>
                    </div>
                  </div>
                }
              </div>
            </mat-tab>

            <!-- Tickets Tab (User's other tickets) -->
            <mat-tab label="Tickets">
              <div class="details-tab">
                @for (
                  ticket of selectedTicket?.user?.supportTickets;
                  track $index
                ) {
                  <div class="ticket-item">
                    <div class="ticket-content">
                      <div class="status">
                        <p
                          class="status_ticket"
                          [class.status-unresolved]="
                            ticket.status === 'unresolved'
                          "
                          [class.status-resolved]="ticket.status === 'resolved'"
                          [class.status-closed]="ticket.status === 'closed'"
                        >
                          {{ ticket.status | uppercase }}
                        </p>
                      </div>
                      <p class="main-concern">
                        <b>{{ ticket.mainConcern }}</b>
                      </p>
                      <p>
                        {{ ticket?.messages?.at(-1)?.text || '' | striphtml }}
                      </p>
                      <p>{{ ticket.createdAt | date }}</p>
                    </div>
                    <div>
                      <a
                        [routerLink]="['.']"
                        target="_blank"
                        [queryParams]="{ uid: ticket.uid }"
                      >
                        <mat-icon>open_in_new</mat-icon>
                      </a>
                    </div>
                  </div>
                } @empty {
                  <p>No tickets found.</p>
                }
              </div>
            </mat-tab>

            <!-- TODO: reminders comp -->
            <!-- Reminders Tab -->
            <mat-tab label="Reminders">
              <ng-template mat-tab-label>
                <span
                  [matBadge]="selectedTicket?.overdueReminders?.length || null"
                  matBadgeOverlap="false"
                  matBadgeColor="warn"
                  >Reminders</span
                >
              </ng-template>
              <div class="details-tab">
                <div class="reminder-container">
                  <h3>New reminder</h3>
                  <form
                    [formGroup]="reminderForm"
                    (ngSubmit)="createReminder()"
                  >
                    <textarea
                      formControlName="note"
                      placeholder="What you write will be visible to all your colleagues."
                    ></textarea>

                    <div class="input-group">
                      <input
                        formControlName="expiresAt"
                        type="date"
                        placeholder="dd-mm-yyyy"
                      />
                    </div>
                    <button
                      type="submit"
                      [disabled]="!reminderForm.valid"
                      class="pinploy_button"
                      [ngClass]="{ disabled: !reminderForm.valid }"
                    >
                      Set reminder
                    </button>
                  </form>
                </div>
                @if (selectedTicket?.reminders?.length > 0) {
                  <div>
                    <h4>All reminders</h4>
                    <ul style="list-style: none">
                      @for (
                        reminder of selectedTicket.reminders;
                        track $index
                      ) {
                        <li>
                          <div class="reminder">
                            <div class="name_checkbox">
                              <div class="content">
                                <div>
                                  <mat-checkbox
                                    [color]="'primary'"
                                    [checked]="reminder?.completedAt"
                                    (change)="
                                      completeSupportTicketReminder(
                                        $event,
                                        reminder.id
                                      )
                                    "
                                  >
                                  </mat-checkbox>
                                </div>
                                <div>
                                  <p>
                                    Created by: {{ reminder?.user?.firstName }}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    Expires:
                                    {{
                                      reminder?.expiresAt | date: 'dd/MM/YYYY'
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style="cursor: pointer"
                              (click)="openReminderDialog(reminder)"
                            >
                              <p
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ reminder?.note }}
                              </p>
                              <a style="color: #009ac6">Read more</a>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                } @else {
                  <p>No reminders.</p>
                }
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      }
    </mat-sidenav>
  </mat-sidenav-container>
</div>
