<div class="page_container">
  <paginated-list
    [data]="conflictCases"
    [filterKeys]="filterKeys"
    (dataRequested)="fetchData()"
    (displayDataChanged)="updatePagination($event)"
  >
    <table class="table">
      <thead>
        <tr class="conflictTableRow">
          <th class="conflictColumn_1">Case ID</th>
          <th class="conflictColumn_1">Task ID</th>
          <th class="conflictColumn_1">Task owner ID</th>
          <th class="conflictColumn_1">Handyhander ID</th>

          <th class="conflictColumn_1">Created at</th>
          <th class="conflictColumn_1">Deadline</th>
          <th class="conflictColumn_1">TO Evidence</th>
          <th class="conflictColumn_1">HH Evidence</th>
          <th class="conflictColumn_1">HH Percentage</th>
          <th class="conflictColumn_1">Status</th>
        </tr>
      </thead>
      <mat-divider></mat-divider>
      <tbody>
        <tr
          *ngFor="let conflictCase of displayConflictCases"
          class="conflictTableRow clickable"
          [routerLink]="['/conflict-cases', conflictCase.id]"
        >
          <mat-divider [vertical]="true"></mat-divider>
          <!-- Conflict case Ids -->
          <td class="conflictColumn_1">{{ conflictCase.id }}</td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Task Ids -->
          <td class="conflictColumn_1">{{ conflictCase.taskId }}</td>
          <mat-divider [vertical]="true"></mat-divider>

          <td class="conflictColumn_1">{{ conflictCase.taskOwnerId }}</td>
          <mat-divider [vertical]="true"></mat-divider>

          <td class="conflictColumn_1">{{ conflictCase.handyhanderId }}</td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Created at -->
          <td class="conflictColumn_1">
            {{ formatDate(conflictCase.createdAt) }}
          </td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Deadline -->
          <td class="conflictColumn_1">
            {{ formatDate(conflictCase.deadline) }}
          </td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Taskowner Evidence -->
          <td
            *ngIf="!conflictCase.existEvidenceTO"
            class="conflictColumn_1 red_text"
          >
            <mat-icon>not_interested</mat-icon>
          </td>
          <td
            *ngIf="conflictCase.existEvidenceTO"
            class="conflictColumn_1 green_text"
          >
            <mat-icon>check</mat-icon>
          </td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Handyhander Evidence -->
          <td
            *ngIf="!conflictCase.existEvidenceHH"
            class="conflictColumn_1 red_text"
          >
            <mat-icon>not_interested</mat-icon>
          </td>
          <td
            *ngIf="conflictCase.existEvidenceHH"
            class="conflictColumn_1 green_text"
          >
            <mat-icon>check</mat-icon>
          </td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Percentage to Handyhander -->
          <td class="conflictColumn_1">
            {{ conflictCase.toHHpercentage?.toString() || 'Not decided' }}
          </td>
          <mat-divider [vertical]="true"></mat-divider>

          <!-- Conflict case Status -->
          <td
            *ngIf="conflictCase.status === 'awaiting-evidence'"
            class="conflictColumn_1 red_text"
          >
            Awaiting evidence
          </td>
          <td
            *ngIf="conflictCase.status === 'pending-decision'"
            class="conflictColumn_1 yellow_text"
          >
            Pending decision
          </td>
          <td
            *ngIf="conflictCase.status === 'closed'"
            class="conflictColumn_1 green_text"
          >
            Closed
          </td>
          <td
            *ngIf="conflictCase.status === 'cancelled'"
            class="conflictColumn_1 grey_text"
          >
            Cancelled
          </td>
          <mat-divider [vertical]="true"></mat-divider>
        </tr>
      </tbody>
    </table>
  </paginated-list>
</div>
