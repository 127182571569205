import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { SupportTicketReminder } from 'src/app/models/supportTicket.model';

@Component({
  selector: 'app-support-ticket-reminder-info-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './support-ticket-reminder-info-dialog.component.html',
  styleUrls: [
    '../dialogs.scss',
    './support-ticket-reminder-info-dialog.component.scss',
  ],
})
export class SupportTicketReminderInfoDialogComponent implements OnInit {
  public reminder: SupportTicketReminder;
  constructor(
    public dialogRef: MatDialogRef<SupportTicketReminderInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.reminder = this.data.reminder;
  }

  close(): void {
    this.dialogRef.close();
  }
}
