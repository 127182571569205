import { RouterModule, Routes } from '@angular/router';

import { BanListComponent } from './pages/banList/banList.component';
import { CancelTaskAutomationComponent } from './pages/cancelTaskAutomationList/cancelTaskAutomation/cancelTaskAutomation.component';
import { CancelTaskAutomationListComponent } from './pages/cancelTaskAutomationList/cancelTaskAutomationList.component';
import { CancelTaskComponent } from './pages/cancelTaskList/cancelTask/cancelTask.component';
import { CancelTaskListComponent } from './pages/cancelTaskList/cancelTaskList.component';
import { LoginComponent } from './pages/login/login.component';
import { OfferComponent } from './pages/offerList/offer/offer.component';
import { OfferListComponent } from './pages/offerList/offerList.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PenaltyComponent } from './pages/penaltyList/penalty/penalty.component';
import { PenaltyListComponent } from './pages/penaltyList/penaltyList.component';
import { ReportComponent } from './pages/reportList/report/report.component';
import { ReportListComponent } from './pages/reportList/reportList.component';
import { TaskComponent } from './pages/taskList/task/task.component';
import { TaskListComponent } from './pages/taskList/taskList.component';
import { UserComponent } from './pages/userList/user/user.component';
import { UserListComponent } from './pages/userList/userList.component';
import { AuthGuard, PreventLoginGuard } from './services/auth.guard';
import { ChatbotLogComponent } from './pages/chatbotLog/chatbotLog.component';
import { CommentListComponent } from './pages/commentList/commentList.component';
import { CompromiseCalculatorComponent } from './pages/compromiseCalculator/compromiseCalculator.component';
import { ConflictCaseComponent } from './pages/conflictCaseList/conflictCase/conflictCase.component';
import { ConflictCaseListComponent } from './pages/conflictCaseList/conflictCaseList.component';
import { ContentFeedComponent } from './pages/content-feed/contentFeed.component';
import { CopuonListComponent } from './pages/coupons/couponList.component';
import { BucketNotificationDetailsComponent } from './pages/notificationBucket/BucketNotificationDetails/bucket-notification-details.component';
import { BucketDefinitionDetailsComponent } from './pages/notificationBucket/bucketDefinitionDetails/bucket-definition-details.component';
import { NotificationBucketComponent } from './pages/notificationBucket/notification-bucket.component';
import { NotificationLogComponent } from './pages/notificationLog/notificationLog.component';
import { NotificationLogDetailsComponent } from './pages/notificationLog/notificationLogDetails/notificationLogDetails.component';
import { NewNotificationSegmentComponent } from './pages/notificationSegment/newNotificationSegment/new-notification-segment.component';
import { NotificationScheduledDetails } from './pages/notificationSegment/notificationScheduledDetails/notification-scheduled-details.component';
import { NotificationSegmentComponent } from './pages/notificationSegment/notificationSegment.component';
import { NotificationSegmentDetails } from './pages/notificationSegment/notificationSegmentDetails/notification-segment-details.component';
import { SearchComponent } from './pages/search/search.component';
import { SystemEvents } from './pages/system-events/system-events.component';
import { TasksTagsComponent } from './pages/tasksTags/tasksTags.component';
import { TaskPrivateConversationComponent } from './pages/taskPrivateConversation/taskPrivateConversation.component';
import { RequestHandlingPageComponent } from './pages/requestHandlingPage/requestHandlingPage.component';
import { SupportTicketListComponentV2 } from './pages/supporTicketListV2/supportTicketListV2.component';

const appRoutes: Routes = [
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  {
    path: 'search/phone/:phoneNumber',
    component: SearchComponent,
    canActivate: [AuthGuard],
  },
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'users/:id', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'tasks', component: TaskListComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:id', component: TaskComponent, canActivate: [AuthGuard] },
  {
    path: 'tasks/:id/:convType/:convId',
    component: TaskComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks-tags',
    component: TasksTagsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'offers', component: OfferListComponent, canActivate: [AuthGuard] },
  { path: 'offers/:id', component: OfferComponent, canActivate: [AuthGuard] },
  {
    path: 'comments',
    component: CommentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comments/:type/:id',
    component: CommentListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'reports', component: ReportListComponent, canActivate: [AuthGuard] },
  { path: 'reports/:id', component: ReportComponent, canActivate: [AuthGuard] },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PreventLoginGuard],
  },
  { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard] },
  {
    path: 'payments/:id',
    component: PaymentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancel-task',
    component: CancelTaskListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancel-task/:id',
    component: CancelTaskComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancel-task-automation',
    component: CancelTaskAutomationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancel-task-automation/:id',
    component: CancelTaskAutomationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'penalties',
    component: PenaltyListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'penalty/:id',
    component: PenaltyComponent,
    canActivate: [AuthGuard],
  },
  { path: 'bans', component: BanListComponent, canActivate: [AuthGuard] },
  {
    path: 'content-feed',
    component: ContentFeedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'coupons',
    component: CopuonListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requests',
    component: RequestHandlingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'support-tickets-v2',
    component: SupportTicketListComponentV2,
    canActivate: [AuthGuard],
  },
  { path: 'system-events', component: SystemEvents, canActivate: [AuthGuard] },
  {
    path: 'chatbot-logs',
    component: ChatbotLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'conflict-cases',
    component: ConflictCaseListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'conflict-cases/:id',
    component: ConflictCaseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'compromise-calculator',
    component: CompromiseCalculatorComponent,
    canActivate: [AuthGuard],
  },

  // NOTIFICATION SEGMENT
  {
    path: 'notification-segments',
    component: NotificationSegmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-segments/new',
    component: NewNotificationSegmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-segments/:id',
    component: NotificationSegmentDetails,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-segments/scheduled/:id',
    component: NotificationScheduledDetails,
    canActivate: [AuthGuard],
  },

  // NOTIFICATION LOG
  {
    path: 'notification-logs',
    component: NotificationLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-logs/:id',
    component: NotificationLogDetailsComponent,
    canActivate: [AuthGuard],
  },

  // NOTIFICATION BUCKET
  {
    path: 'notification-buckets',
    component: NotificationBucketComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-buckets/:id',
    component: BucketDefinitionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-buckets/:defId/:id',
    component: BucketNotificationDetailsComponent,
    canActivate: [AuthGuard],
  },

  // TASK CONVERSATION
  {
    path: 'conversation/:taskId',
    component: TaskPrivateConversationComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'conversation/:taskId/search/:search',
    component: TaskPrivateConversationComponent,
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: 'search' },
];

export const routing = RouterModule.forRoot(appRoutes);
