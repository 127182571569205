import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { MiscService } from 'src/app/services/misc.service';
import { CompressorService } from 'src/app/services/compressor.service';
import { MatDialog } from '@angular/material/dialog';
import { FullScreenImageDialogComponent } from '../../image-preview/fullscreen-image-dialog/fullscreen-image-dialog.component';
@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss', '../dialogs.scss'],
})
export class UploadDocumentDialogComponent {
  resource: string;
  resourceId: number;
  fileType: string;

  imageData: any[] = [];
  imageMedia: any[] = [];
  imageMediaUploaded: any[] = [];
  pdfData: any[] = [];

  acceptedFileTypes: string;

  uploadPath: string;

  createMedia$: any;

  constructor(
    private miscService: MiscService,
    private toastr: ToastrService,
    private compressor: CompressorService,
    private stateService: StateService,
    private fullScreenImageDialog: MatDialog,
    public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.resource = data.resource!;
    this.resourceId = data.resourceId!;
    this.fileType = data.fileType!;
    if (!['image', 'pdf', 'both'].includes(this.fileType)) {
      throw new Error('Invalid fileType. Must be "image", "pdf", or "both".');
    }
  }

  ngOnInit() {
    if (this.fileType === 'image') {
      this.acceptedFileTypes = '.jpg, .jpeg, .png, .dng, .heic, .hevc, .webp';
    } else if (this.fileType === 'pdf') {
      this.acceptedFileTypes = '.pdf';
    } else {
      this.acceptedFileTypes =
        '.jpg, .jpeg, .png, .dng, .heic, .hevc, .webp, .pdf';
    }
  }

  async uploadFile(event: any) {
    this.imageData = event.target.files;
    if (this.imageData.length === 0) {
      return;
    }
    if (
      this.imageData.length > 1 ||
      this.imageData.length + this.imageMediaUploaded.length > 1
    ) {
      this.toastr.error(
        'Du kan kun uploade 1 billede i alt',
        'Billedet kunne ikke uploades',
      );
      return;
    } else {
      this.pdfData = Array.from(this.imageData).filter(
        (file) => file.type === 'application/pdf',
      );
      this.imageData = Array.from(this.imageData).filter(
        (file) => file.type !== 'application/pdf',
      );
      await this.compressImages();
    }
  }

  async compressImages() {
    this.stateService.toggleWaitingPage(true);
    if (this.imageData.length > 0) {
      const toCompressedImages = await Promise.all(
        Array.from(this.imageData).map((file) =>
          this.compressor.compress2(file),
        ),
      );
      this.imageMedia = toCompressedImages;
      this.imageData = [];
    } else {
      this.stateService.toggleWaitingPage(false);
    }

    switch (this.resource) {
      case 'review':
        this.uploadPath = 'reviews';
        break;
      case 'task':
        this.uploadPath = 'tasks';
        break;
      default:
        this.toastr.error('Resource not found / implemented yet');
        break;
    }

    this.createMedia$ = this.miscService.uploadMedia(
      { folderUploadPath: this.uploadPath },
      [...this.imageMedia, ...this.pdfData],
    );

    this.createMedia$.subscribe((mediaResult: any) => {
      this.imageMediaUploaded = this.imageMediaUploaded.concat(
        mediaResult?.files,
      );
      this.imageMedia = [];
      this.pdfData = [];
      this.stateService.toggleWaitingPage(false);
    });
  }

  upload() {
    this.stateService.toggleWaitingPage(true);
    const data = {
      resource: this.resource,
      resourceId: this.resourceId,
      media: this.imageMediaUploaded,
    };

    this.miscService.createMedia(data).subscribe({
      next: (res) => {
        this.stateService.toggleWaitingPage(false);
        this.close(res);
      },
      error: (err) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error('Kunne ikke uploade filen', 'Fejl');
      },
    });
  }

  openImageDialog(media) {
    this.fullScreenImageDialog.open(FullScreenImageDialogComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'fullscreen_image_dialog',
      backdropClass: 'dialog__backdrop',
      data: {
        imageUrl: media?.location,
      },
    });
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  isImageFile(media: any): boolean {
    const allowedExtensions = [
      'image/jpeg',
      'image/png',
      'image/dng',
      'image/heic',
      'image/hevc',
      'image/webp',
      'image/jpg',
    ];
    return allowedExtensions.includes(media);
  }

  deleteImage(media) {
    this.imageMediaUploaded = this.imageMediaUploaded.filter(
      (image) => image !== media,
    );
  }

  openDoc(doc: any) {
    window.open(doc.location, '_blank');
  }
}
