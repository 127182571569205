import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import Quill from 'quill';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import {
  FindSupportTicketsPaginationParams,
  SupportTicketService,
} from 'src/app/services/support.ticket.service';
import {
  SupportTicket,
  SupportTicketReminder,
} from 'src/app/models/supportTicket.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from 'src/app/components/dialogs/questionDialog/questionDialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StateService } from 'src/app/services/state.service';
import { CompressorService } from 'src/app/services/compressor.service';
import { MiscService } from 'src/app/services/misc.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { MatSelectChange } from '@angular/material/select';
import { StorageService } from 'src/app/services/storage.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { UserStoredCreditsHistoryComponent } from 'src/app/components/dialogs/userStoredCreditsHistory/userStoredCreditsHistory.component';
import { BanUserDialogComponent } from 'src/app/components/dialogs/ban-user/banUserDialog.component';
import { UserNotesDialogComponent } from 'src/app/components/dialogs/user-notes/user-notes.component';
import {
  SmartResponsesDialogComponent,
  SmartResponsesDialogData,
} from '../supporTicketList/smart-responses/smart-responses.component';
import { SafeHtmlPipe } from 'src/app/directives/safeHtml.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subscription } from 'rxjs';
import { UsersCurrentlyViewingComponent } from 'src/app/components/users-currently-viewing/users-currently-viewing.component';
import { SocketService } from 'src/app/services/socket.service';
import { SupportTicketReminderInfoDialogComponent } from 'src/app/components/dialogs/support-ticket-reminder-info-dialog/support-ticket-reminder-info-dialog.component';
import { UserStoredCreditsComponent } from 'src/app/components/dialogs/userStoredCredits/userStoredCredits.component';
import { StoredCreditsService } from 'src/app/services/storedCredits.service';
import { UserPenaltiesDialogComponent } from 'src/app/components/dialogs/userPenaltiesDialog/userPenaltiesDialog.component';
import { StripHtmlPipe } from 'src/app/pipes/stripHtml.pipe';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-support-ticket-list-v2',
  templateUrl: './supportTicketListV2.component.html',
  styleUrls: ['./supportTicketListV2.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSlideToggleModule,
    RouterModule,
    MatBadgeModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    SafeHtmlPipe,
    StripHtmlPipe,
    MatInputModule,
    MatFormFieldModule,
    UsersCurrentlyViewingComponent,
  ],
  providers: [],
})
export class SupportTicketListComponentV2
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('editor', { static: true }) editor: ElementRef;
  @ViewChild('quillToolbar') quillToolbar: ElementRef;
  @ViewChild('imagesPicker', { static: true }) imagesPicker: ElementRef;
  @ViewChild('docsPicker', { static: true }) docsPicker: ElementRef;
  @ViewChild('supportTicketListContainer')
  supportTicketListContainer: ElementRef;
  @ViewChild('selectedTicketMessagesContainer')
  selectedTicketMessagesContainer: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent) {
    // Handle message send shortcut
    if (this.selectedTicket && 
        this.messageForm.valid && 
        this.quill?.hasFocus() && 
        (event.ctrlKey || event.metaKey) && 
        event.key === 'Enter') {
      this.sendMessage(this.selectedTicket.id);
      return false;
    }

    // Handle details sidebar toggle
    if (!this.quill?.hasFocus() && (event.ctrlKey || event.metaKey) && event.key === 'b') {
      this.detailsSidebarOpen = !this.detailsSidebarOpen;
      return false;
    }
  }

  tickets: SupportTicket[] = [];
  selectedTicket: SupportTicket | null = null;
  assignees: User[] | null = null;

  reminderForm: FormGroup;
  messageForm: FormGroup;

  quill: Quill | null = null;

  detailsSidebarOpen: boolean = true;

  currentTab: 'open' | 'closed' = 'open';
  openTickets: SupportTicket[] = [];
  closedTickets: SupportTicket[] = [];

  supportTicketsPagination: FindSupportTicketsPaginationParams = {
    page: 1,
    pageSize: 10,
    status: 'open',
    hasMore: true,
  };

  loading: boolean = false;

  unresolvedSupportTicketsCount: number = 0;

  selectedTabIndex: number = 0;

  userHasPenalties: boolean = false;

  public currentlyViewedSupportTicketsSubscription: Subscription;
  public newSupportTicketSubscription: Subscription;
  public newSupportTicketMessageSubscription: Subscription;
  public supportTicketUpdatedSubscription: Subscription;
  public unresolvedSupportTicketsCountSubscription: Subscription;
  public supportTicketClosedSubscription: Subscription;

  constructor(
    private supportTicketService: SupportTicketService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private stateService: StateService,
    private compressor: CompressorService,
    private miscService: MiscService,
    private route: ActivatedRoute,
    private router: Router,
    private socketService: SocketService,
    private storedCreditsService: StoredCreditsService,
    private userService: UserService,
    public storage: StorageService,
  ) {
    this.reminderForm = new FormGroup(
      {
        note: new FormControl<string | null>(null),
        expiresAt: new FormControl<Date>(null),
        createdBy: new FormControl<number>(this.storage.user.id),
      },
      {
        validators: this.reminderFormValidator,
      },
    );
    this.messageForm = new FormGroup(
      {
        text: new FormControl<string | null>(null),
        images: new FormControl<any[] | null>(null),
        docs: new FormControl<any[] | null>(null),
        createdBy: new FormControl<number>(this.storage.user.id),
      },
      {
        validators: this.messageFormValidator,
      },
    );
  }

  async ngOnInit() {
    await this.getSupportTickets();
    this.selectTicket(this.openTickets[0]);
    this.getUnresolvedSupportTicketsCount();
    this.getAssignees();
    this.subscribeToCurrentlyViewedSupportTickets();
    this.subscribeToNewSupportTickets();
    this.subscribeToNewSupportTicketMessages();
    this.subscribeToSupportTicketRemindersCreated();
    this.subscribeToUpdatedSupportTickets();
    this.subscribeToUnresolvedSupportTicketsCount();
    this.subscribeToSupportTicketClosed();

    this.route.queryParamMap.subscribe({
      next: (val) => {
        const uid = val.get('uid');
        if (uid) {
          const openTicket = this.openTickets.find(
            (ticket) => ticket.uid === uid,
          );
          const closedTicket = this.closedTickets.find(
            (ticket) => ticket.uid === uid,
          );

          if (!openTicket && !closedTicket) {
            this.supportTicketService.getSupportTicketByUid(uid).subscribe({
              next: (ticket) => {
                if (openTicket) {
                  this.openTickets = [ticket, ...this.openTickets];
                }
                if (closedTicket) {
                  this.closedTickets = [ticket, ...this.closedTickets];
                }
                this.selectTicket(ticket);
              },
            });
            return;
          }

          this.selectTicket(openTicket || closedTicket);
        }
      },
    });
  }

  subscribeToSupportTicketClosed() {
    this.supportTicketClosedSubscription =
      this.stateService.supportTicketClosedSource.subscribe({
        next: ({ supportTicket, oldStatus }) => {
          if (oldStatus === 'unresolved') {
            this.openTickets = this.openTickets.filter(
              (ticket) => ticket.id !== supportTicket.id,
            );
          }
        },
      });
  }

  subscribeToUnresolvedSupportTicketsCount() {
    this.unresolvedSupportTicketsCountSubscription =
      this.stateService.unresolvedSupportTicketsCountSource.subscribe({
        next: (count) => {
          this.unresolvedSupportTicketsCount += count;
          if (this.unresolvedSupportTicketsCount < 0) {
            this.unresolvedSupportTicketsCount = 0;
          }
        },
      });
  }

  getUnresolvedSupportTicketsCount() {
    this.supportTicketService.getUnresolvedSupportTicketsCount().subscribe({
      next: (count) => {
        this.unresolvedSupportTicketsCount = count;
      },
    });
  }

  subscribeToUpdatedSupportTickets() {
    this.supportTicketUpdatedSubscription =
      this.stateService.supportTicketUpdatedSource.subscribe({
        next: (ticket) => {
          if (this.selectedTicket.id === ticket.id) {
            this.selectTicket(ticket);
          }

          if (ticket.status === 'closed') {
            this.openTickets = this.openTickets.filter(
              (x) => x.id !== ticket.id,
            );
            const closedTicketIndex = this.closedTickets.findIndex(
              (x) => x.id === ticket.id,
            );
            if (closedTicketIndex > -1) {
              this.closedTickets[closedTicketIndex] = ticket;
            }
          }

          if (ticket.status !== 'closed') {
            const openTicketIndex = this.openTickets.findIndex(
              (x) => x.id === ticket.id,
            );
            if (openTicketIndex > -1) {
              this.openTickets[openTicketIndex] = ticket;
            }
          }
          this.getUnresolvedSupportTicketsCount();
        },
      });
  }

  subscribeToNewSupportTickets() {
    this.newSupportTicketSubscription =
      this.stateService.newSupportTicketSource.subscribe({
        next: (ticket) => {
          this.openTickets = [ticket, ...this.openTickets];
        },
      });
  }

  subscribeToNewSupportTicketMessages() {
    this.newSupportTicketMessageSubscription =
      this.stateService.newSupportTicketMessageSource.subscribe({
        next: async (ticket) => {
          if (ticket.status === 'resolved') {
            this.openTickets = this.openTickets.filter(
              (x) => x.id !== ticket.id,
            );
          }
          if (ticket.status === 'unresolved') {
            const ticketIndex = this.openTickets.findIndex(
              (x) => x.id === ticket.id,
            );
            if (ticketIndex > -1) {
              this.openTickets[ticketIndex] = ticket;
            } else {
              this.openTickets = [ticket, ...this.openTickets];
            }
          }

          this.closedTickets = this.closedTickets.filter(
            (x) => x.id !== ticket.id,
          );

          if (this.selectedTicket.id === ticket.id) {
            this.selectTicket(ticket);

            setTimeout(() => {
              this.selectedTicketMessagesContainer.nativeElement.scrollTo({
                top: this.selectedTicketMessagesContainer.nativeElement
                  .scrollHeight,
                behavior: 'smooth',
              });
            }, 100);
          }
        },
      });
  }

  subscribeToCurrentlyViewedSupportTickets() {
    this.currentlyViewedSupportTicketsSubscription =
      this.stateService.currentlyViewedSupportTicketsSource.subscribe({
        next: (tickets) => {
          if (tickets) {
            Object.entries(tickets).forEach(([ticketId, viewedBy]) => {
              // find ticket in openTickets or closedTickets
              const targetTicket = [
                ...this.openTickets,
                ...this.closedTickets,
              ].find((x) => x.id === +ticketId);
              if (!targetTicket) return;
              // remove duplicate users from the list
              const viewedBySet = new Set();
              const viewedByUnique = viewedBy.filter((x) => {
                const duplicate = viewedBySet.has(x.id);
                viewedBySet.add(x.id);
                return !duplicate;
              });
              targetTicket.viewedBy = viewedByUnique;
            });
          }
        },
      });
  }

  subscribeToSupportTicketRemindersCreated() {
    this.stateService.supportTicketReminderCreatedSource.subscribe({
      next: (data) => {
        const { reminder, supportTicket } = data;
        if (this.selectedTicket.id === supportTicket.id) {
          this.selectedTicket.reminders.push(reminder);
          this.handleOverdueReminders();
        }

        this.openTickets = this.openTickets.filter(
          (ticket) => ticket.id !== supportTicket.id,
        );
      },
    });
  }

  onConnectUserToTicket(userId: string) {
    if (!userId) {
      return this.toastr.error('Support ticket ID is missing', 'Error');
    }
    if (!Number.parseInt(userId)) {
      return this.toastr.error('Support ticket ID is invalid', 'Error');
    }

    this.supportTicketService
      .connectSupportTicketToUser(Number(userId), this.selectedTicket.id)
      .subscribe({
        next: (value) => {
          this.selectedTicket = value;
          this.toastr.success('User connected to support ticket', 'Success');
        },
        error: (err) => {
          console.error(err);
          this.toastr.error('Error connecting user to support ticket', 'Error');
        },
      });
  }

  openNotesOverviewDialog() {
    if (!this.selectedTicket?.userId) {
      return;
    }
    this.dialog.open(UserNotesDialogComponent, {
      data: {
        userId: this.selectedTicket.userId,
      },
    });
  }

  openBansOverviewDialog() {
    if (!this.selectedTicket?.userId) {
      return;
    }
    this.dialog.open(BanUserDialogComponent, {
      data: {
        userId: this.selectedTicket.userId,
      },
    });
  }

  openPenaltiesDialog() {
    if (!this.selectedTicket?.userId) {
      return;
    }

    if (!this.userHasPenalties) {
      return;
    }

    this.dialog.open(UserPenaltiesDialogComponent, {
      data: {
        userId: this.selectedTicket.userId,
      },
      width: '50%',
    });
  }

  onSupportTicketListScroll(event): void {
    const container = this.supportTicketListContainer.nativeElement;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const tolerance = 1;

    if (Math.abs(scrollPosition - container.scrollHeight) <= tolerance) {
      this.getSupportTickets();
    }
  }

  reminderFormValidator(control: AbstractControl): ValidationErrors | null {
    const note = control.get('note')?.value;
    const expiresAt = control.get('expiresAt')?.value;
    if (!note || !note.trim()) {
      return { noteRequired: 'Note is required.' };
    }

    if (!expiresAt) {
      return { expiresAtRequired: 'Must set expiry date' };
    }
  }

  messageFormValidator(control: AbstractControl): ValidationErrors | null {
    const text = control.get('text')?.value;
    if (!text || !text.trim()) {
      return { textRequired: 'Text is required.' };
    }
    return null;
  }

  removePreviewImage(image: File) {
    const currentImages = this.messageForm.get('images').value;
    const updatedImages = currentImages.filter((img: File) => img !== image);
    this.messageForm.patchValue({ images: updatedImages });
  }

  removePreviewDoc(doc: File) {
    const currentDocs = this.messageForm.get('docs').value;
    const updatedDocs = currentDocs.filter((file: File) => file !== doc);
    this.messageForm.patchValue({ docs: updatedDocs });
  }

  openPreviewImage(image) {
    const imageUrl = image?.location || image.url;
    window.open(imageUrl, '_blank');
  }

  async downloadFile(file) {
    const fileSrc = file?.location || file.url;
    const fileRes = await fetch(fileSrc);
    const fileBlob = await fileRes.blob();
    const fileURL = URL.createObjectURL(fileBlob);

    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.href = fileURL;
      link.download = file?.originalname || file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async onImagesPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.compressAndUploadImages(filesArray);
      const existingValue = this.messageForm.get('images').value;
      if (existingValue) {
        this.messageForm.patchValue({
          images: [...existingValue, ...uploadedFiles.files],
        });
      } else {
        this.messageForm.patchValue({ images: uploadedFiles.files });
      }
      input.value = '';
    }
  }

  async onDocumentsPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.uploadDocuments(filesArray);
      const existingValue = this.messageForm.get('docs').value;
      if (existingValue) {
        this.messageForm.patchValue({
          dcos: [...existingValue, ...uploadedFiles.files],
        });
      } else {
        this.messageForm.patchValue({ docs: uploadedFiles.files });
      }
      input.value = '';
    }
  }

  async compressAndUploadImages(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise(async (resolve, reject) => {
      if (files.length > 0) {
        const toCompressedImages = await Promise.all(
          files.map((file) => this.compressor.compress2(file)),
        );
        this.miscService
          .uploadMedia(
            { folderUploadPath: 'supportTicket/message' },
            toCompressedImages,
          )
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  async uploadDocuments(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        this.miscService
          .uploadMedia({ folderUploadPath: 'supportTicket/message' }, files)
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  onTicketListClick(event, ticket: SupportTicket) {
    if (event?.ctrlKey || event?.metaKey) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([], {
          queryParams: { uid: ticket.uid },
        }),
      );
      window.open(url, '_blank'); // new tab
      return;
    }

    this.selectTicket(ticket);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { uid: this.selectedTicket.uid },
      queryParamsHandling: 'merge',
    });
  }

  closeTicket(supportTicketId: number) {
    if (!this.selectedTicket || !supportTicketId) {
      return this.toastr.error(
        'Ticket is not selected or ID is missing',
        'Error',
      );
    }
    if (this.selectedTicket.status === 'closed') {
      return this.toastr.error('Ticket is already closed', 'Error');
    }
    this.loading = true;
    const dialog = this.dialog.open(QuestionDialogComponent, {
      data: {
        title: `Closing support ticket ID: ${supportTicketId}`,
        description: 'Are you sure you want to close the this support ticket?',
      },
    });

    dialog.afterClosed().subscribe({
      next: (value) => {
        if (value === 'yes') {
          this.supportTicketService
            .closeSupportTicket(supportTicketId)
            .subscribe({
              next: () => {
                this.toastr.info(
                  `Support ticket with ID: ${supportTicketId} has been closed`,
                  'Ticket closed',
                );
                this.onTicketClosed(supportTicketId);
                this.loading = false;
              },
              error: (err) => {
                console.error(err);
                this.toastr.error(
                  `Support ticket ID: ${supportTicketId} could not be closed`,
                  'Error closing ticket',
                );
                this.loading = false;
              },
            });
        }
      },
    });
  }

  onTicketClosed(supportTicketId: number) {
    this.selectedTicket = null;
    this.openTickets = this.openTickets.filter(
      (ticket) => ticket.id !== supportTicketId,
    );
    this.getUnresolvedSupportTicketsCount();
  }

  completeSupportTicketReminder(
    event: MatCheckboxChange,
    supportTicketReminderId: number,
  ) {
    let completedAt = null;
    if (event.checked) {
      completedAt = new Date();
    }
    this.supportTicketService
      .completeSupportTicketReminder(
        this.selectedTicket.id,
        supportTicketReminderId,
        completedAt,
      )
      .subscribe({
        next: (value) => {
          if (completedAt) {
            this.selectedTicket.reminders = this.selectedTicket.reminders.map(
              (reminder) => {
                if (reminder.id === supportTicketReminderId) {
                  reminder.completedAt = value.completedAt;
                }
                return reminder;
              },
            );
            this.handleOverdueReminders();
            return this.toastr.info(
              `Reminder marked as completed`,
              'Reminder completed',
            );
          }
          this.selectedTicket.reminders = this.selectedTicket.reminders.map(
            (reminder) => {
              if (reminder.id === supportTicketReminderId) {
                reminder.completedAt = null;
              }
              return reminder;
            },
          );
          this.handleOverdueReminders();
          return this.toastr.info(
            `Reminder set as not completed`,
            'Completion removed',
          );
        },
        error: (err) => {},
      });
  }

  getSupportTickets() {
    if (this.loading || !this.supportTicketsPagination.hasMore) {
      return;
    }

    this.loading = true;
    this.stateService.toggleWaitingPage(true);

    return new Promise((resolve, reject) => {
      this.supportTicketService
        .getSupportTicketsPaginated(this.supportTicketsPagination)
        .subscribe({
          next: (paginatedResponse) => {
            if (this.currentTab === 'open') {
              this.openTickets = [
                ...this.openTickets,
                ...paginatedResponse.tickets,
              ];
            } else {
              this.closedTickets = [
                ...this.closedTickets,
                ...paginatedResponse.tickets,
              ];
            }
            this.supportTicketsPagination.hasMore =
              paginatedResponse.tickets.length ===
              this.supportTicketsPagination.pageSize;
            this.supportTicketsPagination.page++;

            this.loading = false;
            this.stateService.toggleWaitingPage(false);
            resolve(paginatedResponse);
            if (this.currentlyViewedSupportTicketsSubscription) {
              this.currentlyViewedSupportTicketsSubscription.unsubscribe();
            }
            this.subscribeToCurrentlyViewedSupportTickets();
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
            this.stateService.toggleWaitingPage(false);
            reject();
          },
        });
    });
  }

  getAssignees() {
    this.supportTicketService.getAssignees().subscribe({
      next: (assignees) => {
        this.assignees = assignees;
      },
    });
  }

  updateSupportTicketAssignee(event: MatSelectChange) {
    const selectedAssignee = this.assignees.find(
      (assignee) => assignee.id === event.value,
    );
    this.supportTicketService
      .updateSupportTicketAssignee(
        this.selectedTicket.id,
        this.selectedTicket.assigneeId,
      )
      .subscribe({
        next: (value) => {
          if (!value) {
            this.toastr.error(
              'Assignee was not updated',
              'Error updating assignee',
            );
            return;
          }
          if (selectedAssignee) {
            this.toastr.success(
              `${selectedAssignee.firstName} assigned to support ticket ID=${this.selectedTicket.id}`,
              'Assignee updated',
            );
          } else {
            this.toastr.success(
              `Assignee removed from support ticket ID=${this.selectedTicket.id}`,
              'Assignee removed',
            );
          }
        },
        error: (err) => console.error(err),
      });
  }

  sendMessage(supportTicketId: number) {
    if (!this.messageForm.valid) {
      this.toastr.warning('Message must have text', 'Invalid message');
      return;
    }
    this.stateService.toggleWaitingPage(true);
    this.supportTicketService
      .createSupportTicketMessage(supportTicketId, this.messageForm.value)
      .subscribe({
        next: async (message) => {
          this.toastr.success('Message sent');
          this.messageForm.reset({
            createdBy: this.storage.user.id,
          });
          this.quill.setText('');

          this.openTickets = this.openTickets.filter(
            (ticket) => ticket.id !== supportTicketId,
          );
          this.closedTickets = this.closedTickets.filter(
            (ticket) => ticket.id !== supportTicketId,
          );
          this.stateService.toggleWaitingPage(false);

          setTimeout(() => {
            this.selectedTicketMessagesContainer.nativeElement.scrollTo({
              top: this.selectedTicketMessagesContainer.nativeElement
                .scrollHeight,
              behavior: 'smooth',
            });
          }, 100);
        },
        error: (err) => {
          console.error(err);
          this.stateService.toggleWaitingPage(false);
          this.toastr.error('Error sending message', 'Message was not sent');
        },
      });
  }

  createReminder() {
    if (this.reminderForm.invalid) {
      this.toastr.warning('Invalid reminder data', 'Error creating reminder');
      return;
    }

    this.stateService.toggleWaitingPage(true);
    this.supportTicketService
      .createSupportTicketReminder(
        this.selectedTicket.id,
        this.reminderForm.value,
      )
      .subscribe({
        next: (value) => {
          // this.selectedTicket.reminders.push(value);
          this.toastr.success(
            `Reminder for ticket ID=${this.selectedTicket.id} created`,
            'Reminder created',
          );
          this.handleOverdueReminders();
          this.reminderForm.reset({
            createdBy: this.storage.user.id,
          });
          this.openTickets = this.openTickets.filter(
            (ticket) => ticket.id !== this.selectedTicket.id,
          );
          this.stateService.toggleWaitingPage(false);
          this.getUnresolvedSupportTicketsCount();
        },
        error: (err) => {
          console.error(err);
          this.toastr.error(
            'Reminder was not created.',
            'Error creating reminder',
          );
          this.stateService.toggleWaitingPage(false);
        },
      });
  }

  async onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.currentTab = 'open';
      this.supportTicketsPagination.status = 'open';
      this.selectedTabIndex = 0;
    } else {
      this.currentTab = 'closed';
      this.supportTicketsPagination.status = 'closed';
      this.selectedTabIndex = 1;
    }
    this.resetSupportTicketsPagination();
    this.getSupportTickets();
  }

  resetSupportTicketsPagination() {
    this.supportTicketsPagination.page = 1;
    this.supportTicketsPagination.hasMore = true;
    if (this.currentTab === 'open') {
      this.openTickets = [];
    } else {
      this.closedTickets = [];
    }
  }

  emitStopViewingEntityEvent() {
    if (!this.selectedTicket) {
      return;
    }
    this.socketService.emitEvent({
      eventName: 'stop_viewing_entity',
      data: {
        entityId: this.selectedTicket.id.toString(),
        entityType: 'support_ticket',
      },
    });
  }

  emitViewingEntityEvent() {
    if (!this.selectedTicket) {
      return;
    }
    this.socketService.emitEvent({
      eventName: 'viewing_entity',
      data: {
        entityId: this.selectedTicket.id.toString(),
        entityType: 'support_ticket',
      },
    });
  }

  selectTicket(ticket: SupportTicket) {
    if (!ticket) {
      return;
    }
    if (this.selectedTicket) {
      this.emitStopViewingEntityEvent();
    }
    this.selectedTicket = ticket;

    if (this.selectedTicket?.status === 'closed') {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }

    this.emitViewingEntityEvent();
    this.handleOverdueReminders();

    if (this.selectedTicket.userId) {
      this.getUserStoredCreditsBalance(this.selectedTicket.userId);
      this.getUserPenalties(this.selectedTicket.userId);
    }

    setTimeout(() => {
      this.selectedTicketMessagesContainer.nativeElement.scrollTo({
        top: this.selectedTicketMessagesContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  getUserPenalties(userId: number) {
    this.userService.getPenalties(userId).subscribe({
      next: (penalties) => {
        this.userHasPenalties =
          this.selectedTicket.userId && penalties.length > 0;
      },
    });
  }

  getUserStoredCreditsBalance(userId: number) {
    this.storedCreditsService.getBalance(userId).subscribe((res) => {
      this.selectedTicket.user.storedCreditsBalance = res.balance;
    });
  }

  openStoredCreditsDialog() {
    if (!this.selectedTicket?.userId) {
      return;
    }
    this.dialog.open(UserStoredCreditsHistoryComponent, {
      data: {
        userId: this.selectedTicket.userId,
      },
    });
  }

  openWithdrawStoredCreditsDialog() {
    if (!this.selectedTicket?.userId) {
      return;
    }
    if (this.selectedTicket?.user?.storedCreditsBalance <= 0) {
      return;
    }
    const dialog = this.dialog.open(UserStoredCreditsComponent, {
      data: {
        userId: this.selectedTicket.userId,
      },
    });

    dialog.afterClosed().subscribe((res) => {
      this.getUserStoredCreditsBalance(this.selectedTicket.userId);
    });
  }

  openReminderDialog(reminder: SupportTicketReminder) {
    this.dialog.open(SupportTicketReminderInfoDialogComponent, {
      data: {
        reminder,
      },
      width: '30vw',
    });
  }

  handleOverdueReminders() {
    this.selectedTicket.overdueReminders = this.selectedTicket?.reminders?.filter(
      (reminder) => {
        if (
          !reminder?.completedAt &&
          new Date().getTime() >= Date.parse(reminder?.expiresAt)
        ) {
          return reminder;
        }
      },
    );
  }

  initQuill() {
    this.quill = new Quill(this.editor.nativeElement, {
      theme: 'snow',
      placeholder: 'Write your reply...',
      modules: {
        toolbar: {
          container: document.querySelector('#quill-toolbar'),
        },
      },
    });

    // Handle all text changes (typing, smart responses, mixed)
    this.quill.on('text-change', () => {
      const html = this.quill.root.innerHTML;
      
      // Update form with the HTML content
      this.messageForm.patchValue({
        text: html,
      });
    });
  }

  openSmartResponses() {
    const dialogRef = this.dialog.open<
      SmartResponsesDialogComponent,
      SmartResponsesDialogData
    >(SmartResponsesDialogComponent, {
      data: {
        supportTicket: this.selectedTicket,
      },
      width: '50vw',
    });

    const sub = dialogRef.componentInstance.emailTemplateSelected.subscribe(
      (template) => {
        // Get current cursor position
        const range = this.quill.getSelection(true);
        
        // Insert a newline before the template if we're not at the start
        if (range.index > 0) {
          this.quill.insertText(range.index, '\n');
          range.index += 1;
        }

        // Insert the template content
        if (template.response) {
          if (this.isHtml(template.response)) {
            // For HTML content, insert at current position
            this.quill.clipboard.dangerouslyPasteHTML(
              range.index,
              template.response,
              'api'
            );
          } else {
            // For plain text, just insert as text
            this.quill.insertText(range.index, template.response);
          }
        }

        // Insert a newline after the template
        const length = this.quill.getLength();
        this.quill.insertText(length, '\n');
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  private isHtml(text: string): boolean {
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlTagRegex.test(text.trim());
  }

  ngAfterViewInit() {
    this.initQuill();
  }

  ngOnDestroy() {
    this.currentlyViewedSupportTicketsSubscription.unsubscribe();
    this.newSupportTicketSubscription.unsubscribe();
    this.newSupportTicketMessageSubscription.unsubscribe();
    this.emitStopViewingEntityEvent();
  }

  markAsResolved(ticketId: number) {
    this.supportTicketService.markSupportTicketAsResolved(ticketId).subscribe({
      next: (updatedTicket) => {
        // Update local ticket status
        this.selectedTicket.status = 'resolved';
        
        // Remove from open tickets if present
        const index = this.openTickets.findIndex(t => t.id === ticketId);
        if (index !== -1) {
          this.openTickets.splice(index, 1);
        }
        
        // Add to closed tickets if not present
        if (!this.closedTickets.find(t => t.id === ticketId)) {
          this.closedTickets.unshift(updatedTicket);
        }
        
        this.toastr.success('Ticket marked as resolved');
      },
      error: (error) => {
        this.toastr.error('Failed to mark ticket as resolved');
        console.error('Error marking ticket as resolved:', error);
      }
    });
  }
}
