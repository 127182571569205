import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequest } from 'src/app/models/adminActionRequest.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-request-payout-failed',
  templateUrl: './requestPayoutFailed.component.html',
  styleUrls: [
    './requestPayoutFailed.component.scss',
    '../requestHandlingPage.component.scss',
  ],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  providers: [],
  animations: [
    trigger('fadeUpAnimation', [
      transition(':leave', [
        animate(
          '1000ms ease-out',
          keyframes([
            style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }), // Initial state
            style({ opacity: 1, transform: 'translateY(-2%)', offset: 0.4 }), // Start slow
            style({ opacity: 0, transform: 'translateY(-5%)', offset: 1 }), // Dissapear
          ]),
        ),
      ]),
    ]),
  ],
})
export class RequestPayoutFailedComponent implements OnInit {
  @Input({ required: true }) request: AdminActionRequest;
  requestHandledEvent = output<AdminActionRequest>();

  isLoading: boolean = false;
  requestHandled: boolean = false;
  payoutData: any;

  constructor(
    private adminActionRequestService: AdminActionRequestService,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.payoutData = this.request?.data?.payoutData;
  }

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'Cancellation denied request marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
  
}
